import { baseCSF } from "Base/utils/store"

import {
  TGetProductsTypesReqPayload,
  TGetProductsTypesSuccessPayload,
  TGetProductsTypesFailurePayload,
  TPutProductsTypesSuccessPayload,
  TPutProductsTypesFailurePayload,
  TPutProductsTypesReqPayload,
  TDeleteProductsTypesReqPayload,
  TPostProductsTypesFailurePayload,
  TDeleteProductsTypesSuccessPayload,
  TPostProductsTypesSuccessPayload,
  TDeleteProductsTypesFailurePayload,
  TPostProductsTypesReqPayload,
} from "./types"

const prefix = "products-types"

export const getProductsTypesAction = baseCSF<
  TGetProductsTypesReqPayload,
  TGetProductsTypesSuccessPayload,
  TGetProductsTypesFailurePayload
>(prefix, "get")

export const postProductsTypesAction = baseCSF<
  TPostProductsTypesReqPayload,
  TPostProductsTypesSuccessPayload,
  TPostProductsTypesFailurePayload
>(prefix, "post")

export const putProductsTypesAction = baseCSF<
  TPutProductsTypesReqPayload,
  TPutProductsTypesSuccessPayload,
  TPutProductsTypesFailurePayload
>(prefix, "put")

export const deleteProductsTypesAction = baseCSF<
  TDeleteProductsTypesReqPayload,
  TDeleteProductsTypesSuccessPayload,
  TDeleteProductsTypesFailurePayload
>(prefix, "delete")
