import { apiRequest } from "Base/provider/sagas"
import * as productsTypesActions from "Base/store/productsTypes/actions"
import {
  TDeleteProductsTypesResp,
  TGetProductsTypesResp,
  TPostProductsTypesResp,
  TPutProductsTypesResp,
} from "Base/types/provider/api/productsTypes"
import { takeLatest, call, put } from "redux-saga/effects"

import {
  TDeleteProductsTypesReqAction,
  TPostProductsTypesReqAction,
  TPutProductsTypesReqAction,
} from "./types"

export function* getProductsTypesSaga() {
  try {
    const resp: TGetProductsTypesResp = yield call(apiRequest, "getProductsTypes")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsTypesActions.getProductsTypesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsTypesActions.getProductsTypesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postProductsTypesSaga({ payload }: TPostProductsTypesReqAction) {
  try {
    const resp: TPostProductsTypesResp = yield call(apiRequest, "postProductsTypes", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsTypesActions.postProductsTypesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsTypesActions.postProductsTypesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putProductsTypesSaga({ payload }: TPutProductsTypesReqAction) {
  try {
    const resp: TPutProductsTypesResp = yield call(apiRequest, "putProductsTypes", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsTypesActions.putProductsTypesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsTypesActions.putProductsTypesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteProductsTypesSaga({ payload }: TDeleteProductsTypesReqAction) {
  try {
    const resp: TDeleteProductsTypesResp = yield call(apiRequest, "deleteProductsTypes", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsTypesActions.deleteProductsTypesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsTypesActions.deleteProductsTypesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* productsTypesWatcher() {
  yield takeLatest(productsTypesActions.getProductsTypesAction.call.type, getProductsTypesSaga)
  yield takeLatest(productsTypesActions.postProductsTypesAction.call.type, postProductsTypesSaga)
  yield takeLatest(productsTypesActions.putProductsTypesAction.call.type, putProductsTypesSaga)
  yield takeLatest(
    productsTypesActions.deleteProductsTypesAction.call.type,
    deleteProductsTypesSaga,
  )
}
