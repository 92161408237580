import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Search from "antd/es/input/Search"
import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import * as mixesActions from "Base/store/mixes/actions"
import * as productsActions from "Base/store/products/actions"
import { TPostProductsReqPayload, TPutProductsReqPayload } from "Base/store/products/types"
import * as productsTypesActions from "Base/store/productsTypes/actions"
import { TProduct } from "Base/types/provider/api/products"
import { RootState } from "Starter/store/configureStore"
import { Button, Form, Input, InputNumber, Modal, Select, Space, Table, Typography } from "antd"

const { Title } = Typography
const { Item } = Form

type TFormValues = TPostProductsReqPayload | TPutProductsReqPayload
type TDataType = TProduct

export const Products = () => {
  const dispatch = useDispatch()
  const {
    base: {
      productsTypes: { getProductsTypes },
      products: { getProducts, deleteProducts, putProducts, postProducts },
      mixes: { getMixes },
    },
  } = useSelector((state: RootState) => state)
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [selectValues, setSelectValues] = useState<number[]>([])
  const [selectedTypeId, setSelectedTypeId] = useState<number>()
  const [form] = Form.useForm()
  const id = form.getFieldValue("id")
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})

  useEffect(() => {
    dispatch(productsTypesActions.getProductsTypesAction.call({}))
    dispatch(mixesActions.getMixesAction.call({}))
  }, [])

  const columns: ColumnsType<TDataType> = useMemo(() => {
    return [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        width: 60,
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "МДЖ %",
        dataIndex: "fatPercentage",
        key: "fatPercentage",
        width: 150,
        sorter: (a, b) => a.fatPercentage - b.fatPercentage,
        sortOrder: sortedInfo.columnKey === "fatPercentage" ? sortedInfo.order : null,
      },
      {
        title: "МДБ %",
        dataIndex: "proteinPercentage",
        key: "proteinPercentage",
        width: 150,
        sorter: (a, b) => a.proteinPercentage - b.proteinPercentage,
        sortOrder: sortedInfo.columnKey === "proteinPercentage" ? sortedInfo.order : null,
      },
      {
        title: "Тип продукта",
        dataIndex: "type",
        key: "type",
        render: (_, record: TDataType) => {
          return <>{record.type.name}</>
        },
        sorter: (a, b) => a.type.name.localeCompare(b.type.name),
        sortOrder: sortedInfo.columnKey === "type" ? sortedInfo.order : null,
      },
      {
        title: "Смесь",
        dataIndex: "mix",
        key: "mix",
        render: (_, record: TDataType) => {
          return <>{record.mix.name}</>
        },
        sorter: (a, b) => a.mix.name.localeCompare(b.mix.name),
        sortOrder: sortedInfo.columnKey === "mix" ? sortedInfo.order : null,
      },
      {
        title: "Действие",
        key: "action",
        width: 150,
        render: (_, record: TDataType) => {
          return (
            <Space size='middle'>
              <Button
                onClick={() => {
                  form.setFieldValue("id", record.id)
                  form.setFieldValue("name", record.name)
                  form.setFieldValue("fatPercentage", record.fatPercentage)
                  form.setFieldValue("proteinPercentage", record.proteinPercentage)
                  form.setFieldValue("mixId", record.mix.id)
                  form.setFieldValue("typeId", record.type.id)
                  form.setFieldValue("mixConsumption", record.mixConsumption)
                  setSelectedTypeId(record.type.id)
                  setIsOpen(true)
                }}
                type='primary'
                icon={<EditOutlined />}
              >
                Изменить
              </Button>
            </Space>
          )
        },
      },
    ]
  }, [getProducts.data, sortedInfo])

  useEffect(() => {
    if (postProducts.isLoading || putProducts.isLoading || deleteProducts.isLoading) return
    dispatch(productsActions.getProductsAction.call({}))
  }, [postProducts.isLoading, putProducts.isLoading, deleteProducts.isLoading])

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
  }, [isOpen])

  const onFinish = (values: TFormValues) => {
    values.name = values.name.trim()
    id
      ? dispatch(
          productsActions.putProductsAction.call({
            ...values,
            mixId: values.mixId,
            typeId: values.typeId,
            id,
          } as TPutProductsReqPayload),
        )
      : dispatch(
          productsActions.postProductsAction.call({
            ...values,
            mixId: values.mixId,
            typeId: values.typeId,
          } as TPostProductsReqPayload),
        )
    setIsOpen(false)
  }

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }
  const deleteProduct = () => {
    dispatch(productsActions.deleteProductsAction.call({ id }))
    setIsOpen(false)
    setIsOpenDeleteConfirmModal(false)
  }

  const productsData = useMemo(() => {
    if (!getProducts.data?.result) return []
    return getProducts.data?.result
      .filter((product) => {
        const isValuesFromSelectValues =
          selectValues.length > 0 ? selectValues.includes(product.type.id) : true
        return searchValue && isValuesFromSelectValues
          ? product.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
          : isValuesFromSelectValues
      })
      .sort()
  }, [getProducts.data, searchValue, selectValues])

  const productsTypesOptions = useMemo(() => {
    if (!getProductsTypes.data?.result) return []
    return getProductsTypes.data?.result.map((productsType) => {
      return { value: productsType.id, label: productsType.name }
    })
  }, [getProductsTypes.data])

  const mixesOptions = useMemo(() => {
    if (!getMixes.data?.result) return []
    return getMixes.data?.result.map((mixe) => {
      return { value: mixe.id, label: mixe.name }
    })
  }, [getMixes.data])

  const onSelect = (value: number[]) => {
    setSelectValues(value)
  }

  const productsTypesObject = useMemo(() => {
    const _productsTypesObject: { [key: string]: boolean } = {}
    getProductsTypes.data?.result.forEach((productType) => {
      _productsTypesObject[productType.id] = productType.mixConsumptionEnabled
    })
    return _productsTypesObject
  }, [getProductsTypes.data?.result])

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }

  return (
    <>
      <Space direction='vertical'>
        <Space>
          <Title level={2} style={{ marginBottom: 0 }}>
            Готовые продукты
          </Title>
          <Button onClick={() => setIsOpen(true)} type='primary' icon={<PlusCircleOutlined />}>
            Добавить
          </Button>
        </Space>
        <Space style={{ marginTop: 30 }}>
          <Search onChange={onChange} placeholder='Поиск по названию' />
          <Select
            mode='multiple'
            allowClear
            style={{ minWidth: 200 }}
            onChange={onSelect}
            options={productsTypesOptions}
            placeholder='Выберите тип продукта'
          />
        </Space>
        <Space style={{ marginTop: 30 }}>
          <Table
            style={{ maxWidth: 1200 }}
            columns={columns}
            dataSource={productsData}
            onChange={handleChange}
            pagination={false}
            locale={{ emptyText: "Нет данных" }}
          />
        </Space>
      </Space>
      <Modal
        title='Добавление/редактирование готового продукта'
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Form
          name='mixes'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 8 }}
          onFinish={onFinish}
        >
          <Item name='name' label='Название' required>
            <Input placeholder='Название' required />
          </Item>
          <Item name='fatPercentage' label='МДЖ %' required>
            <InputNumber
              placeholder='МДЖ'
              required
              decimalSeparator=','
              precision={2}
              max={99.99}
              min={0}
            />
          </Item>
          <Item name='proteinPercentage' label='МДБ %'>
            <InputNumber placeholder='МДБ' decimalSeparator=',' precision={2} max={99.99} />
          </Item>
          <Item
            name='typeId'
            label='Тип продукта'
            rules={[{ required: true, message: "Заполните это поле" }]}
          >
            <Select
              style={{ minWidth: 200 }}
              options={productsTypesOptions}
              onSelect={(event) => setSelectedTypeId(event)}
            />
          </Item>
          <Item
            name='mixId'
            label='Смесь'
            rules={[{ required: true, message: "Заполните это поле" }]}
          >
            <Select style={{ minWidth: 200 }} options={mixesOptions} />
          </Item>
          {selectedTypeId && productsTypesObject[selectedTypeId] && (
            <Item name='mixConsumption' label='Расход смеси кг / т' required>
              <InputNumber placeholder='Расход смеси кг / т' decimalSeparator=',' precision={2} />
            </Item>
          )}

          <Space direction={"horizontal"}>
            <Button type='primary' htmlType='submit'>
              Сохранить
            </Button>
            {id && (
              <Button
                onClick={() => setIsOpenDeleteConfirmModal(true)}
                type='primary'
                danger
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            )}
          </Space>
        </Form>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteConfirmModal}
        onOk={() => setIsOpenDeleteConfirmModal(false)}
        onCancel={() => setIsOpenDeleteConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            Вы уверены, что хотите удалить данный продукт?
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={deleteProduct}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
            <Button onClick={() => setIsOpenDeleteConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </>
  )
}
