import { Card } from "antd"

export const ErrorCard = ({ errors }: { errors: string[] }) => {
  return (
    <Card
      bordered
      style={{ borderColor: "#f73c3c", backgroundColor: "#f73c3c15" }}
      headStyle={{ borderColor: "#f73c3c81" }}
      title='Ошибка при расчете'
    >
      <ul style={{ padding: "0 1em", margin: 0, display: "grid", gap: "5px" }}>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </Card>
  )
}
