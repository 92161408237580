import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as productsTypesActions from "Base/store/productsTypes/actions"
import {
  TDeleteProductsTypesData,
  TGetProductsTypesData,
  TPostProductsTypesData,
  TPutProductsTypesData,
} from "Base/types/provider/api/productsTypes"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteProductsTypesFailureAction,
  TDeleteProductsTypesSuccessAction,
  TGetProductsTypesFailureAction,
  TGetProductsTypesSuccessAction,
  TPostProductsTypesFailureAction,
  TPostProductsTypesSuccessAction,
  TPutProductsTypesFailureAction,
  TPutProductsTypesSuccessAction,
} from "./types"

export type TProductsTypesStateType = {
  getProductsTypes: {
    data?: TGetProductsTypesData
  } & RequestInfoChildType
  postProductsTypes: {
    data?: TPostProductsTypesData
  } & RequestInfoChildType
  putProductsTypes: {
    data?: TPutProductsTypesData
  } & RequestInfoChildType
  deleteProductsTypes: {
    data?: TDeleteProductsTypesData
  } & RequestInfoChildType
}

const initialState: TProductsTypesStateType = {
  getProductsTypes: {
    ...RequestInfoChildState,
  },
  postProductsTypes: {
    ...RequestInfoChildState,
  },
  putProductsTypes: {
    ...RequestInfoChildState,
  },
  deleteProductsTypes: {
    ...RequestInfoChildState,
  },
}

export const productsTypesReducer = createReducer(initialState, {
  [productsTypesActions.getProductsTypesAction.call.type]: (state) => ({
    ...state,
    getProductsTypes: {
      ...state.getProductsTypes,
      isLoading: true,
    },
  }),
  [productsTypesActions.getProductsTypesAction.success.type]: (
    state,
    action: TGetProductsTypesSuccessAction,
  ) => ({
    ...state,
    getProductsTypes: {
      ...state.getProductsTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsTypesActions.getProductsTypesAction.failure.type]: (
    state,
    action: TGetProductsTypesFailureAction,
  ) => ({
    ...state,
    getProductsTypes: {
      ...state.getProductsTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [productsTypesActions.postProductsTypesAction.call.type]: (state) => ({
    ...state,
    postProductsTypes: {
      ...state.postProductsTypes,
      isLoading: true,
    },
  }),
  [productsTypesActions.postProductsTypesAction.success.type]: (
    state,
    action: TPostProductsTypesSuccessAction,
  ) => ({
    ...state,
    postProductsTypes: {
      ...state.postProductsTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsTypesActions.postProductsTypesAction.failure.type]: (
    state,
    action: TPostProductsTypesFailureAction,
  ) => ({
    ...state,
    postProductsTypes: {
      ...state.postProductsTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [productsTypesActions.putProductsTypesAction.call.type]: (state) => ({
    ...state,
    putProductsTypes: {
      ...state.putProductsTypes,
      isLoading: true,
    },
  }),
  [productsTypesActions.putProductsTypesAction.success.type]: (
    state,
    action: TPutProductsTypesSuccessAction,
  ) => ({
    ...state,
    putProductsTypes: {
      ...state.putProductsTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsTypesActions.putProductsTypesAction.failure.type]: (
    state,
    action: TPutProductsTypesFailureAction,
  ) => ({
    ...state,
    putProductsTypes: {
      ...state.putProductsTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [productsTypesActions.deleteProductsTypesAction.call.type]: (state) => ({
    ...state,
    deleteProductsTypes: {
      ...state.deleteProductsTypes,
      isLoading: true,
    },
  }),
  [productsTypesActions.deleteProductsTypesAction.success.type]: (
    state,
    action: TDeleteProductsTypesSuccessAction,
  ) => ({
    ...state,
    deleteProductsTypes: {
      ...state.deleteProductsTypes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsTypesActions.deleteProductsTypesAction.failure.type]: (
    state,
    action: TDeleteProductsTypesFailureAction,
  ) => ({
    ...state,
    deleteProductsTypes: {
      ...state.deleteProductsTypes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
