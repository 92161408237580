import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  DeleteMixesParams,
  DeleteMixesReq,
  DeleteMixesResp,
  GetMixesByIdParams,
  GetMixesByIdReq,
  GetMixesByIdResp,
  GetMixesParams,
  GetMixesReq,
  GetMixesResp,
  PostMixesParams,
  TPostMixesReq,
  PostMixesResp,
  PutMixesParams,
  PutMixesReq,
  PutMixesResp,
} from "Base/types/provider/api/mixes"

export default class MixesProvider extends ApiProviderDefault {
  static async deleteMixes(parameters: { params: DeleteMixesParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/mixes/${id}/`
    const body = undefined
    const query = undefined
    return this.request<DeleteMixesReq, DeleteMixesResp>(path, "DELETE", query, body, headers)
  }

  static async getMixesById(parameters: { params: GetMixesByIdParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/mixes/${id}`
    const body = undefined
    const query = undefined
    return this.request<GetMixesByIdReq, GetMixesByIdResp>(path, "GET", query, body, headers)
  }

  static async getMixes(parameters: { params: GetMixesParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/mixes`
    const body = undefined
    const query = undefined
    return this.request<GetMixesReq, GetMixesResp>(path, "GET", query, body, headers)
  }

  static async postMixes(parameters: { params: PostMixesParams; headers: Headers }) {
    const { params, headers } = parameters
    const path = "/mixes"
    const query = {}
    const body = params
    return this.request<TPostMixesReq, PostMixesResp>(path, "POST", query, body, headers)
  }

  static async putMixes(parameters: { params: PutMixesParams; headers: Headers }) {
    const {
      params: { id, name, fatPercentage, proteinPercentage },
      headers,
    } = parameters
    const path = `/mixes/${id}`
    const body = { name, fatPercentage, proteinPercentage }
    const query = undefined
    return this.request<PutMixesReq, PutMixesResp>(path, "PUT", query, body, headers)
  }
}
