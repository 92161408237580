import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { clientModuleRoutes } from "Base/constants/routes"
import * as authPersistActions from "Base/store/authPersist/actions"
import { RootState } from "Starter/store/configureStore"
import { Button, Form, Input, Modal, Space } from "antd"

type TFormValues = {
  password: string
}

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    base: {
      authPersist: { auth },
    },
  } = useSelector((state: RootState) => state)
  const [form] = Form.useForm()

  const onFinish = (values: TFormValues) => {
    dispatch(
      authPersistActions.setPasswordAction.call({
        password: values.password,
      }),
    )
  }

  const [isValid, setIsValid] = useState(false)
  return (
    <>
      {auth && children}

      <Modal
        title='Вход'
        centered
        open={!auth}
        onOk={() => navigate(clientModuleRoutes.assortment.root)}
        onCancel={() => navigate(clientModuleRoutes.assortment.root)}
        footer={false}
      >
        <Form name='mixes' form={form} style={{ marginTop: 20 }} onFinish={onFinish}>
          <Form.Item
            name='password'
            label='Пароль'
            rules={[
              { required: true },
              ({ getFieldsValue }) => ({
                validator(_, value) {
                  if (value === "pVmOYs") {
                    setIsValid(true)
                    return Promise.resolve()
                  } else {
                    setIsValid(false)
                    return Promise.reject("Неверный пароль")
                  }
                },
              }),
            ]}
          >
            <Input.Password type='password' placeholder='Пароль' required />
          </Form.Item>

          <Space direction={"horizontal"}>
            <Button disabled={!isValid} type='primary' htmlType='submit'>
              Войти
            </Button>
            <Button
              onClick={() => {
                navigate(clientModuleRoutes.assortment.root)
              }}
            >
              Вернуться к калькулятору
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  )
}
