import { TDairyProcessingMetrics } from "../../types"
import { calculateCheeseWheyEnabled } from "./calculateCheeseWheyEnabled"
import { calculateCottageCheeseWheyWeight } from "./calculateCottageCheeseWheyWeight"

export function calculateCheeseAndCottage({
  dairyProcessingMetrics,
  cottageCheeseWheyEnabled,
  cheeseWheyEnabled,
  currentWeight,
  _currentWeight,
}: {
  dairyProcessingMetrics: TDairyProcessingMetrics
  cottageCheeseWheyEnabled: boolean
  cheeseWheyEnabled: boolean
  currentWeight: number
  _currentWeight: number
}) {
  let temporalDairyProcessingMetrics = dairyProcessingMetrics
  if (cottageCheeseWheyEnabled) {
    temporalDairyProcessingMetrics = calculateCottageCheeseWheyWeight({
      state: dairyProcessingMetrics,
      currentWeight,
      _currentWeight,
    })
  }

  if (cheeseWheyEnabled) {
    temporalDairyProcessingMetrics = calculateCheeseWheyEnabled({
      state: dairyProcessingMetrics,
      currentWeight,
      _currentWeight,
    })
  }

  return temporalDairyProcessingMetrics
}
