import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Search from "antd/es/input/Search"
import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import * as mixesActions from "Base/store/mixes/actions"
import { TMixe } from "Base/types/provider/api/mixes"
import { RootState } from "Starter/store/configureStore"
import { Button, Form, Input, InputNumber, Modal, Space, Table, Typography } from "antd"

const { Title } = Typography
const { Item } = Form

type TFormValues = TMixe

type TDataType = TMixe

export const Mixes = () => {
  const dispatch = useDispatch()
  const {
    base: {
      mixes: { getMixes, postMixes, putMixes, deleteMixes },
    },
  } = useSelector((state: RootState) => state)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [form] = Form.useForm()
  const id = form.getFieldValue("id")
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})

  const columns: ColumnsType<TDataType> = useMemo(() => {
    return [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        width: 60,
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "МДЖ %",
        dataIndex: "fatPercentage",
        key: "fatPercentage",
        width: 150,
        sorter: (a, b) => a.fatPercentage - b.fatPercentage,
        sortOrder: sortedInfo.columnKey === "fatPercentage" ? sortedInfo.order : null,
      },
      {
        title: "МДБ %",
        dataIndex: "proteinPercentage",
        key: "proteinPercentage",
        width: 150,
        sorter: (a, b) => a.proteinPercentage - b.proteinPercentage,
        sortOrder: sortedInfo.columnKey === "proteinPercentage" ? sortedInfo.order : null,
      },
      {
        title: "Действие",
        key: "action",
        width: 150,
        render: (_, record: TDataType) => {
          return (
            <Space size='middle'>
              <Button
                onClick={() => {
                  form.setFieldValue("id", record.id)
                  form.setFieldValue("name", record.name)
                  form.setFieldValue("proteinPercentage", record.proteinPercentage)
                  form.setFieldValue("fatPercentage", record.fatPercentage)
                  setIsOpen(true)
                }}
                type='primary'
                icon={<EditOutlined />}
              >
                Изменить
              </Button>
            </Space>
          )
        },
      },
    ]
  }, [getMixes.data, sortedInfo])

  useEffect(() => {
    if (postMixes.isLoading || putMixes.isLoading || deleteMixes.isLoading) return
    dispatch(mixesActions.getMixesAction.call({}))
  }, [postMixes.isLoading, putMixes.isLoading, deleteMixes.isLoading])

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
  }, [isOpen])

  const onFinish = (values: TFormValues) => {
    values.name = values.name.trim()
    id
      ? dispatch(
          mixesActions.putMixesAction.call({
            ...values,
            proteinPercentage: values.proteinPercentage || 0,
            id,
          }),
        )
      : dispatch(
          mixesActions.postMixesAction.call({
            ...values,
            proteinPercentage: values.proteinPercentage || 0,
          }),
        )
    setIsOpen(false)
  }

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const deleteMixe = () => {
    dispatch(mixesActions.deleteMixesAction.call({ id }))
    setIsOpen(false)
    setIsOpenDeleteConfirmModal(false)
  }
  const mixesData = useMemo(() => {
    if (!getMixes.data?.result) return []
    return searchValue
      ? getMixes.data?.result.filter(
          (mixe) => mixe.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1,
        )
      : getMixes.data?.result
  }, [getMixes.data, searchValue])

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }
  return (
    <>
      <Space direction='vertical'>
        <Space>
          <Title level={2} style={{ marginBottom: 0 }}>
            Смеси
          </Title>
          <Button onClick={() => setIsOpen(true)} type='primary' icon={<PlusCircleOutlined />}>
            Добавить
          </Button>
        </Space>
        <Space style={{ marginTop: 30 }}>
          <Search onChange={onChange} placeholder='Поиск по названию' />
        </Space>
        <Space style={{ marginTop: 30 }}>
          <Table
            style={{ maxWidth: 1200 }}
            columns={columns}
            dataSource={mixesData}
            onChange={handleChange}
            pagination={false}
            locale={{ emptyText: "Нет данных" }}
          />
        </Space>
      </Space>
      <Modal
        title='Добавление/редактирование смеси'
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Form
          name='mixes'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 5 }}
          onFinish={onFinish}
        >
          <Item name='name' label='Название' required>
            <Input placeholder='Название' required />
          </Item>
          <Item name='fatPercentage' label='МДЖ %' required>
            <InputNumber
              placeholder='МДЖ %'
              required
              decimalSeparator=','
              precision={2}
              max={99.99}
              min={0}
            />
          </Item>
          <Item name='proteinPercentage' label='МДБ %'>
            <InputNumber placeholder='МДБ %' decimalSeparator=',' precision={2} max={99.99} />
          </Item>
          <Space direction={"horizontal"}>
            <Button type='primary' htmlType='submit'>
              Сохранить
            </Button>
            {id && (
              <Button
                onClick={() => setIsOpenDeleteConfirmModal(true)}
                type='primary'
                danger
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            )}
          </Space>
        </Form>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteConfirmModal}
        onOk={() => setIsOpenDeleteConfirmModal(false)}
        onCancel={() => setIsOpenDeleteConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            Вы уверены, что хотите удалить данную Смесь? В этом случае будут удалены все продукты,
            относящиеся к данной смеси.
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={deleteMixe}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
            <Button onClick={() => setIsOpenDeleteConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </>
  )
}
