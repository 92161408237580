import { DownloadOutlined } from "@ant-design/icons"
import { Button } from "antd"
import * as XLSX from "xlsx"

export const ExportCSV = ({
  dataForExportCSV,
  fileName,
}: {
  dataForExportCSV: Array<Array<string | number>>
  fileName: string
}) => {
  const exportToCSV = () => {
    const ws = XLSX.utils.aoa_to_sheet(dataForExportCSV)
    ws["!cols"] = [
      { wch: Math.max(35, 35) },
      { wch: Math.max(1, 10) },
      { wch: Math.max(4, 4) },
      { wch: Math.max(35, 35) },
      { wch: Math.max(1, 10) },
    ]

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Export")
    XLSX.writeFile(wb, fileName + ".xlsx")
  }

  return (
    <Button onClick={exportToCSV}>
      <DownloadOutlined /> Экспорт в Excel
    </Button>
  )
}

// This component is a presentational component which takes the data to download and file name as props. The exportToCSV method is invoked when the export button is clicked on line 20.
