import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteProductsParams,
  TDeleteProductsReq,
  TDeleteProductsResp,
  TGetProductsByIdParams,
  TGetProductsByIdReq,
  TGetProductsByIdResp,
  TGetProductsParams,
  TGetProductsReq,
  TGetProductsResp,
  TPostProductsParams,
  TPostProductsReq,
  TPostProductsResp,
  TPutProductsParams,
  TPutProductsReq,
  TPutProductsResp,
} from "Base/types/provider/api/products"

export default class ProductsProvider extends ApiProviderDefault {
  static async deleteProducts(parameters: { params: TDeleteProductsParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/products/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteProductsReq, TDeleteProductsResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getProductsById(parameters: { params: TGetProductsByIdParams; headers: Headers }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/products/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetProductsByIdReq, TGetProductsByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getProducts(parameters: { params: TGetProductsParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/products`
    const body = undefined
    const query = undefined
    return this.request<TGetProductsReq, TGetProductsResp>(path, "GET", query, body, headers)
  }

  static async postProducts(parameters: { params: TPostProductsParams; headers: Headers }) {
    const { params: body, headers } = parameters
    const path = "/products"
    const query = {}
    return this.request<TPostProductsReq, TPostProductsResp>(path, "POST", query, body, headers)
  }

  static async putProducts(parameters: { params: TPutProductsParams; headers: Headers }) {
    const {
      params: { id, name, fatPercentage, proteinPercentage, mixConsumption, mixId, typeId },
      headers,
    } = parameters
    const path = `/products/${id}`
    const body = { name, fatPercentage, proteinPercentage, mixConsumption, mixId, typeId }
    const query = undefined
    return this.request<TPutProductsReq, TPutProductsResp>(path, "PUT", query, body, headers)
  }
}
