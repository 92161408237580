import { notification } from "antd"

import { TAssortment } from "../../types"

export const primaryValidation = ({
  assortments,
  rawMilkQuantity,
  rawMilkFat,
  creamFat,
  freeFatMilkFatOrig,
}: {
  assortments: TAssortment[]
  rawMilkQuantity: number
  rawMilkFat: number
  creamFat: number
  freeFatMilkFatOrig: number
}): boolean => {
  if (assortments.length === 0) {
    notification.error({
      message: "Ошибка расчета",
      description: "Перед расчетом необходимо заполнить ассортимент",
      placement: "topRight",
      type: "error",
    })
  }
  if (!rawMilkQuantity || !rawMilkFat || !creamFat) {
    notification.error({
      message: "Ошибка расчета",
      description: "Недопустимые значения параметров сырого молока и сливок",
      placement: "topRight",
      type: "error",
    })
  }

  const stopCalculation = false
  assortments.forEach((assortment: TAssortment) => {
    if (stopCalculation) return
    let _currentFat // Жирность продукта в цикле
    const {
      product: {
        fatPercentage: currentFatProduct,
        type: { mixConsumptionEnabled },
        mix: { fatPercentage: currentFatMix },
      },
    } = assortment
    if (mixConsumptionEnabled) {
      _currentFat = currentFatMix // 'жир смеси
    } else {
      _currentFat = currentFatProduct //'жир продукта
    }
    if (typeof freeFatMilkFatOrig === "number" && _currentFat < freeFatMilkFatOrig) {
      // stopCalculation = true
    }
  })

  if (stopCalculation) {
    notification.error({
      message: "Ошибка расчета",
      description: "МДЖ обрата должна быть меньше или равна МДЖ смеси продукта",
      placement: "topRight",
      type: "error",
      duration: null,
    })
  }

  return stopCalculation
}
