import { all, fork } from "redux-saga/effects"

import { calculatorWatcher } from "./calculatorPersist/sagas"
import { mixesWatcher } from "./mixes/sagas"
import { productsWatcher } from "./products/sagas"
import { productsTypesWatcher } from "./productsTypes/sagas"

export function* baseRootSaga() {
  try {
    yield all([fork(productsWatcher)])
    yield all([fork(mixesWatcher)])
    yield all([fork(productsTypesWatcher)])
    yield all([fork(calculatorWatcher)])
  } finally {
    // always runs
  }
}
