import { modulesRoutes } from "./modulesRoutes"

const getClientRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Client",
    assortment: {
      root: `${url}/assortment`,
      title: "1. Ассортимент продуктов",
    },
    "calculation-of-raw-materials": {
      root: `${url}/calculation-of-raw-materials`,
      title: "2. Расчет сырья",
    },
    // product-range: {
    //   root: `${url}/product-range`,
    //   title: "Ассортимент продуктов",
    // },
  }
}

export const clientModuleRoutes = getClientRoutes(modulesRoutes.client.root)
