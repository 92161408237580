import { TDairyProcessingMetrics, TStep2Metrics } from "../../types"

export const defaultDairyProcessingMetrics: TDairyProcessingMetrics = {
  rawMilkWeightForLowFatProducts: 0,
  rawMilkWeightForMediumFatProducts: 0,
  rawMilkWeightForHiFatProducts: 0,

  creamWeightForLowFatProducts: 0,
  creamWeightForMediumFatProducts: 0,
  creamWeightForHiFatProducts: 0,

  freeFatMilkVolumeForFreeFatProducts: 0,
  freeFatMilkVolumeForLowFatProducts: 0,
  freeFatMilkVolumeForMediumFatProducts: 0,
  freeFatMilkVolumeForHiFatProducts: 0,

  freeFatMilkVolumeForAllProducts: 0,
  freeFatMilkVolumeForMediumFatProductsIteration: 0,
  freeFatMilkVolumeFromLowFatProducts: 0,

  creamWeightFromLowFatProducts: 0,
  freeFatMilkWeightFromHiFatProducts: 0,
  butterMilkWeightFromHiFatProducts: 0,

  creamWeightIteration: 0,
  lowFatWeightIteration: 0,
  rawMilkWeightIteration: 0,
  butterMilkWeightIteration: 0,

  lowFatWeightForLowFatProducts: 0,

  deltaRawMilkNeedForLowFatProducts: 0,
  deltaCreamFromLowFatProducts: 0,
  deltaFreeFatMilkVolumeForLowFatProducts: 0,

  cottageCheeseWheyWeight: 0,
  cheeseWheyWeight: 0,
}

export const defaultStep2Metrics: TStep2Metrics = {
  rawMilkNeedWeight: 0,
  creamWeightNeedWeight: 0,
  freeFatMilkNeedWeight: 0,
  creamOutputWeight: 0,
  creamCurrentWeight: 0,
  freeFatMilkOutputWeight: 0,
  freeFatMilkCurrentWeight: 0,
  butterMilkWeight: 0,
}
