import { TDairyProcessingMetrics, TStep2Metrics } from "../../types"

/**
 * @description Обновление веса и объема ингредиентов при производстве продукции средней жирности в объеме
 */
export const calculateForMediumFatProductsInVolume = ({
  state,
  step2Metrics,
  _currentFat,
  rawMilkFat,
  freeFatMilkFat,
  _currentWeight,
  creamFat,
}: {
  state: TDairyProcessingMetrics
  step2Metrics: TStep2Metrics
  _currentFat: number
  rawMilkFat: number
  freeFatMilkFat: number
  _currentWeight: number
  creamFat: number
}): { step2Metrics: TStep2Metrics; dairyProcessingMetrics: TDairyProcessingMetrics } => {
  let creamWeightIteration =
    (_currentWeight * (_currentFat - freeFatMilkFat)) / (creamFat - freeFatMilkFat)

  let freeFatMilkCurrentWeight = step2Metrics.freeFatMilkCurrentWeight
  let creamWeightForMediumFatProducts = state.creamWeightForMediumFatProducts
  let rawMilkWeightForMediumFatProducts = state.rawMilkWeightForMediumFatProducts

  const freeFatMilkVolumeForMediumFatProductsIteration = _currentWeight - creamWeightIteration

  if (freeFatMilkVolumeForMediumFatProductsIteration <= freeFatMilkCurrentWeight) {
    freeFatMilkCurrentWeight =
      freeFatMilkCurrentWeight - freeFatMilkVolumeForMediumFatProductsIteration

    creamWeightForMediumFatProducts += creamWeightIteration
  } else {
    const _currentWeightAvailable =
      (freeFatMilkCurrentWeight * (creamFat - freeFatMilkFat)) / (creamFat - _currentFat)

    creamWeightIteration =
      (_currentWeightAvailable * (_currentFat - freeFatMilkFat)) / (creamFat - freeFatMilkFat)

    const _currentWeightLeft = _currentWeight - _currentWeightAvailable

    const rawMilkWeightIteration =
      (_currentWeightLeft * (creamFat - _currentFat)) / (creamFat - rawMilkFat)

    creamWeightIteration +=
      (_currentWeightLeft * (_currentFat - rawMilkFat)) / (creamFat - rawMilkFat)

    creamWeightForMediumFatProducts += creamWeightIteration
    rawMilkWeightForMediumFatProducts += rawMilkWeightIteration
  }

  return {
    dairyProcessingMetrics: {
      ...state,
      creamWeightForMediumFatProducts,
      creamWeightIteration,
      rawMilkWeightForMediumFatProducts,
    },
    step2Metrics: {
      ...step2Metrics,
      freeFatMilkCurrentWeight,
    },
  }
}
