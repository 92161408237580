import { ExportCSV } from "Base/containers/ExportCSV"
import { TCalculate } from "Base/store/calculatorPersist/types"
import { formatDateAndTime } from "Base/utils/dateTime"
import { Descriptions, Space, Typography, Tag, Row, Col } from "antd"

const { Title } = Typography

export const CalculationResults = ({
  data,
  title,
  dataForCSV,
}: {
  data?: TCalculate
  title: string
  dataForCSV: (string | number)[][]
}) => {
  const getValidateValueNumber = (value?: number) => {
    return value === 0 || (value && typeof value === "number") ? (
      Math.round(value)
    ) : (
      <Tag color='red'>Ошибка</Tag>
    )
  }
  return (
    <>
      <Row align='middle' gutter={[24, 24]} style={{ marginBottom: "24px" }}>
        <Col>
          <Title style={{ marginBottom: "4px" }} level={3}>
            {title}
          </Title>
        </Col>
        <Col>
          <ExportCSV
            dataForExportCSV={dataForCSV}
            fileName={`${title} ${formatDateAndTime(new Date())}`}
          />
        </Col>
      </Row>

      <Row style={{ maxWidth: "600px" }}>
        <Col span={14}>
          <Space direction='vertical'>
            <Descriptions column={1} size='small' title='Баланс:'>
              <Descriptions.Item label='Расход сырого молока, кг'>
                {getValidateValueNumber(data?.rawMilkNeedWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Остаток сырого молока, кг'>
                {getValidateValueNumber(data?.rawMilkQuantity)}
              </Descriptions.Item>
              <br />
              <Descriptions.Item label='Выход сливок, кг'>
                {getValidateValueNumber(data?.creamOutputWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Остаток сливок, кг'>
                {getValidateValueNumber(data?.creamCurrentWeight)}
              </Descriptions.Item>
              <br />
              <Descriptions.Item label='Выход обрата, кг'>
                {getValidateValueNumber(data?.freeFatMilkOutputWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Остаток обрата, кг'>
                {getValidateValueNumber(data?.freeFatMilkCurrentWeight)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Col>
        <Col span={10}>
          <Space direction='vertical'>
            <Descriptions column={1} size='small' title='Вторичное сырьё:'>
              <Descriptions.Item label='Творожная сыворотка, кг'>
                {getValidateValueNumber(data?.cottageCheeseWheyWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Подсырная сыворотка, кг'>
                {getValidateValueNumber(data?.cheeseWheyWeight)}
              </Descriptions.Item>
              <Descriptions.Item label='Пахта, кг'>
                {getValidateValueNumber(data?.butterMilkWeightFromHiFatProducts)}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Col>
      </Row>
    </>
  )
}
