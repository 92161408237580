import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as mixesActions from "Base/store/mixes/actions"
import {
  TDeleteMixesData,
  TGetMixesData,
  TPostMixesData,
  PutMixesData,
} from "Base/types/provider/api/mixes"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TDeleteMixesFailureAction,
  TDeleteMixesSuccessAction,
  TGetMixesFailureAction,
  GetMixesSuccessAction,
  TPostMixesFailureAction,
  TPostMixesSuccessAction,
  TPutMixesFailureAction,
  TPutMixesSuccessAction,
} from "./types"

export type MixesStateType = {
  getMixes: {
    data?: TGetMixesData
  } & RequestInfoChildType
  postMixes: {
    data?: TPostMixesData
  } & RequestInfoChildType
  putMixes: {
    data?: PutMixesData
  } & RequestInfoChildType
  deleteMixes: {
    data?: TDeleteMixesData
  } & RequestInfoChildType
}

const initialState: MixesStateType = {
  getMixes: {
    ...RequestInfoChildState,
  },
  postMixes: {
    ...RequestInfoChildState,
  },
  putMixes: {
    ...RequestInfoChildState,
  },
  deleteMixes: {
    ...RequestInfoChildState,
  },
}

export const mixesReducer = createReducer(initialState, {
  [mixesActions.getMixesAction.call.type]: (state) => ({
    ...state,
    getMixes: {
      ...state.getMixes,
      isLoading: true,
    },
  }),
  [mixesActions.getMixesAction.success.type]: (state, action: GetMixesSuccessAction) => ({
    ...state,
    getMixes: {
      ...state.getMixes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [mixesActions.getMixesAction.failure.type]: (state, action: TGetMixesFailureAction) => ({
    ...state,
    getMixes: {
      ...state.getMixes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [mixesActions.postMixesAction.call.type]: (state) => ({
    ...state,
    postMixes: {
      ...state.postMixes,
      isLoading: true,
    },
  }),
  [mixesActions.postMixesAction.success.type]: (state, action: TPostMixesSuccessAction) => ({
    ...state,
    postMixes: {
      ...state.postMixes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [mixesActions.postMixesAction.failure.type]: (state, action: TPostMixesFailureAction) => ({
    ...state,
    postMixes: {
      ...state.postMixes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [mixesActions.putMixesAction.call.type]: (state) => ({
    ...state,
    putMixes: {
      ...state.putMixes,
      isLoading: true,
    },
  }),
  [mixesActions.putMixesAction.success.type]: (state, action: TPutMixesSuccessAction) => ({
    ...state,
    putMixes: {
      ...state.putMixes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [mixesActions.putMixesAction.failure.type]: (state, action: TPutMixesFailureAction) => ({
    ...state,
    putMixes: {
      ...state.putMixes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [mixesActions.deleteMixesAction.call.type]: (state) => ({
    ...state,
    deleteMixes: {
      ...state.deleteMixes,
      isLoading: true,
    },
  }),
  [mixesActions.deleteMixesAction.success.type]: (state, action: TDeleteMixesSuccessAction) => ({
    ...state,
    deleteMixes: {
      ...state.deleteMixes,
      data: action.payload,
      isLoading: false,
    },
  }),
  [mixesActions.deleteMixesAction.failure.type]: (state, action: TDeleteMixesFailureAction) => ({
    ...state,
    deleteMixes: {
      ...state.deleteMixes,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
