import React from "react"

import { BaseRouter } from "Starter/routes"

import styles from "./App.module.scss"

export function App() {
  return (
    <div className={styles.app}>
      <BaseRouter />
    </div>
  )
}
