export const getCurrentWeightAndFat = ({
  mixConsumptionEnabled,
  currentWeight,
  mixConsumption,
  currentFatMix,
  currentFatProduct,
}: {
  mixConsumptionEnabled: boolean
  currentWeight: number
  mixConsumption: number
  currentFatMix: number
  currentFatProduct: number
}) => {
  const _currentWeight = mixConsumptionEnabled
    ? (currentWeight * mixConsumption) / 1000
    : currentWeight

  const _currentFat = mixConsumptionEnabled ? currentFatMix : currentFatProduct

  return {
    _currentWeight,
    _currentFat,
  }
}
