import ApiProviderDefault from "Base/provider/apiProviderDefault"
import {
  TDeleteProductsTypesParams,
  TDeleteProductsTypesReq,
  TDeleteProductsTypesResp,
  TGetProductsTypesByIdParams,
  TGetProductsTypesByIdReq,
  TGetProductsTypesByIdResp,
  TGetProductsTypesParams,
  TGetProductsTypesReq,
  TGetProductsTypesResp,
  TPostProductsTypesParams,
  TPostProductsTypesReq,
  TPostProductsTypesResp,
  TPutProductsTypesParams,
  TPutProductsTypesReq,
  TPutProductsTypesResp,
} from "Base/types/provider/api/productsTypes"

export default class ProductsTypesProvider extends ApiProviderDefault {
  static async deleteProductsTypes(parameters: {
    params: TDeleteProductsTypesParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/products/types/${id}/`
    const body = undefined
    const query = undefined
    return this.request<TDeleteProductsTypesReq, TDeleteProductsTypesResp>(
      path,
      "DELETE",
      query,
      body,
      headers,
    )
  }

  static async getProductsTypesById(parameters: {
    params: TGetProductsTypesByIdParams
    headers: Headers
  }) {
    const {
      params: { id },
      headers,
    } = parameters
    const path = `/products/types/${id}`
    const body = undefined
    const query = undefined
    return this.request<TGetProductsTypesByIdReq, TGetProductsTypesByIdResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async getProductsTypes(parameters: { params: TGetProductsTypesParams; headers: Headers }) {
    const { headers } = parameters
    const path = `/products/types`
    const body = undefined
    const query = undefined
    return this.request<TGetProductsTypesReq, TGetProductsTypesResp>(
      path,
      "GET",
      query,
      body,
      headers,
    )
  }

  static async postProductsTypes(parameters: {
    params: TPostProductsTypesParams
    headers: Headers
  }) {
    const { params: body, headers } = parameters
    const path = "/products/types"
    const query = {}
    return this.request<TPostProductsTypesReq, TPostProductsTypesResp>(
      path,
      "POST",
      query,
      body,
      headers,
    )
  }

  static async putProductsTypes(parameters: { params: TPutProductsTypesParams; headers: Headers }) {
    const {
      params: {
        id,
        name,
        mixConsumptionEnabled,
        lowFatMilkVolume,
        buttermilkEnabled,
        cheeseWheyEnabled,
        cottageCheeseWheyEnabled,
      },
      headers,
    } = parameters
    const path = `/products/types/${id}`
    const body = {
      name,
      mixConsumptionEnabled,
      lowFatMilkVolume,
      buttermilkEnabled,
      cheeseWheyEnabled,
      cottageCheeseWheyEnabled,
    }
    const query = undefined
    return this.request<TPutProductsTypesReq, TPutProductsTypesResp>(
      path,
      "PUT",
      query,
      body,
      headers,
    )
  }
}
