import { TDairyProcessingMetrics } from "../../types"

/**
 * @description обновления веса сыворотки от производства творога в состоянии на основе разницы между модифицированным и текущим весом продукта, если это требуется
 */
export const calculateCottageCheeseWheyWeight = ({
  state,
  currentWeight,
  _currentWeight,
}: {
  state: TDairyProcessingMetrics
  currentWeight: number
  _currentWeight: number
}) => {
  return {
    ...state,
    cottageCheeseWheyWeight: state.cottageCheeseWheyWeight + (_currentWeight - currentWeight),
  }
}
