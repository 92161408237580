import { TDairyProcessingMetrics } from "../../types"

/**
 * @description Расчет сливок и сырого молока при производстве низкожирных продуктов в потоке
 */
export const calculateForLowFatProductsInStream = ({
  state,
  _currentWeight,
  creamFat,
  _currentFat,
  rawMilkFat,
}: {
  state: TDairyProcessingMetrics
  _currentWeight: number
  creamFat: number
  _currentFat: number
  rawMilkFat: number
}) => {
  const rawMilkWeightIteration =
    (_currentWeight * (creamFat - _currentFat)) / (creamFat - rawMilkFat)

  const creamWeightIteration = rawMilkWeightIteration - _currentWeight

  return {
    ...state,
    rawMilkWeightIteration,
    creamWeightIteration,
    creamWeightFromLowFatProducts: state.creamWeightFromLowFatProducts + creamWeightIteration,
    rawMilkWeightForLowFatProducts: state.rawMilkWeightForLowFatProducts + rawMilkWeightIteration,
  }
}
