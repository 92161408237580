import React from "react"
import { useSelector } from "react-redux"
import { Route, Navigate } from "react-router-dom"

import { clientModuleRoutes } from "Base/constants/routes/clientModuleRoutes"
import { MainLayout } from "Base/layouts/MainLayout"
import { Assortments } from "ClientModule/pages/Assortments"
import { CalculationOfRawMaterials } from "ClientModule/pages/CalculationOfRawMaterials"

import { RootState } from "../../Starter/store/configureStore"

export const getClientRouters = () => {
  const {
    base: {
      calculatorPersist: { assortments },
    },
  } = useSelector((state: RootState) => state)
  return (
    <>
      <Route
        path={clientModuleRoutes["assortment"].root}
        element={
          <MainLayout moduleType='client'>
            <Assortments />
          </MainLayout>
        }
      />
      {!!assortments.length && (
        <Route
          path={clientModuleRoutes["calculation-of-raw-materials"].root}
          element={
            <MainLayout moduleType='client'>
              <CalculationOfRawMaterials />
            </MainLayout>
          }
        />
      )}
      <Route
        path={`${clientModuleRoutes.root}/*`}
        element={<Navigate to={clientModuleRoutes["assortment"].root} replace={false} />}
      />
    </>
  )
}
