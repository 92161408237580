import { baseCSF } from "Base/utils/store"

import {
  TDeleteMixesFailurePayload,
  TDeleteMixesReqPayload,
  TDeleteMixesSuccessPayload,
  TGetMixesFailurePayload,
  TGetMixesReqPayload,
  TGetMixesSuccessPayload,
  TPostMixesFailurePayload,
  TPostMixesReqPayload,
  TPostMixesSuccessPayload,
  TPutMixesFailurePayload,
  TPutMixesReqPayload,
  TPutMixesSuccessPayload,
} from "./types"

const prefix = "mixes"

export const getMixesAction = baseCSF<
  TGetMixesReqPayload,
  TGetMixesSuccessPayload,
  TGetMixesFailurePayload
>(prefix, "get")

export const postMixesAction = baseCSF<
  TPostMixesReqPayload,
  TPostMixesSuccessPayload,
  TPostMixesFailurePayload
>(prefix, "post")

export const putMixesAction = baseCSF<
  TPutMixesReqPayload,
  TPutMixesSuccessPayload,
  TPutMixesFailurePayload
>(prefix, "put")

export const deleteMixesAction = baseCSF<
  TDeleteMixesReqPayload,
  TDeleteMixesSuccessPayload,
  TDeleteMixesFailurePayload
>(prefix, "delete")
