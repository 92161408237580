import { TCalculationConfig } from "../../types"

export const streamToVolumeConfigs: TCalculationConfig[] = [
  {
    types: ["freeFatProduct", "lowFatProduct", "mediumFatProduct", "hiFatProduct"],
    methods: ["stream"],
  },
  {
    types: ["freeFatProduct", "lowFatProduct", "hiFatProduct"],
    methods: ["volume"],
  },
  {
    types: ["mediumFatProduct"],
    methods: ["volume"],
  },
]

export const volumeToStreamConfigs: TCalculationConfig[] = [
  {
    types: ["freeFatProduct", "lowFatProduct", "hiFatProduct"],
    methods: ["volume"],
  },
  {
    types: ["mediumFatProduct"],
    methods: ["volume"],
  },
  {
    types: ["freeFatProduct", "lowFatProduct", "hiFatProduct"],
    methods: ["stream"],
  },
]
