import { createReducer } from "@reduxjs/toolkit"
import * as calculatorActions from "Base/store/calculatorPersist/actions"

import {
  TAssortment,
  TCalculate,
  TCalculateSuccessAction,
  TChangeConfirmModal,
  TInputData,
  TSetAssortmentsReqAction,
  TSetChangeConfirmModalReqAction,
  TSetInputDataReqAction,
} from "./types"

export type MixesStateType = {
  assortments: TAssortment[]
  inputData: TInputData
  calculate?: TCalculate
  extraCalculate?: TCalculate
  changeConfirmModal?: TChangeConfirmModal | undefined
  calculateErrors: string[]
}

const initialState: MixesStateType = {
  assortments: [],
  inputData: {},
  calculate: {
    rawMilkNeedWeight: 0,
    rawMilkQuantity: 0,
    creamOutputWeight: 0,
    creamCurrentWeight: 0,
    freeFatMilkOutputWeight: 0,
    freeFatMilkCurrentWeight: 0,
    cottageCheeseWheyWeight: 0,
    cheeseWheyWeight: 0,
    butterMilkWeightFromHiFatProducts: 0,
  },
  extraCalculate: {
    rawMilkNeedWeight: 0,
    rawMilkQuantity: 0,
    creamOutputWeight: 0,
    creamCurrentWeight: 0,
    freeFatMilkOutputWeight: 0,
    freeFatMilkCurrentWeight: 0,
    cottageCheeseWheyWeight: 0,
    cheeseWheyWeight: 0,
    butterMilkWeightFromHiFatProducts: 0,
  },
  calculateErrors: [],
  changeConfirmModal: undefined,
}

export const calculatorPersistReducer = createReducer(initialState, {
  [calculatorActions.setAssortmentsAction.call.type]: (state, action: TSetAssortmentsReqAction) => {
    return {
      ...state,
      assortments: action.payload,
    }
  },
  //============================================================
  [calculatorActions.setInputDataAction.call.type]: (state, action: TSetInputDataReqAction) => {
    return {
      ...state,
      inputData: {
        ...state.inputData,
        ...action.payload,
      },
    }
  },
  //============================================================
  [calculatorActions.setInitInputDataAction.call.type]: (state) => {
    return {
      ...state,
      inputData: {},
    }
  },
  //============================================================
  [calculatorActions.calculateAction.success.type]: (state, action: TCalculateSuccessAction) => {
    return {
      ...state,
      calculate: action.payload,
    }
  },
  [calculatorActions.extraCalculateAction.success.type]: (
    state,
    action: TCalculateSuccessAction,
  ) => {
    return {
      ...state,
      extraCalculate: action.payload,
    }
  },
  //============================================================
  [calculatorActions.setInitCalculateDataAction.call.type]: (state) => {
    return {
      ...state,
      calculate: {
        rawMilkNeedWeight: 0,
        rawMilkQuantity: 0,
        creamOutputWeight: 0,
        creamCurrentWeight: 0,
        freeFatMilkOutputWeight: 0,
        freeFatMilkCurrentWeight: 0,
        cottageCheeseWheyWeight: 0,
        cheeseWheyWeight: 0,
        butterMilkWeightFromHiFatProducts: 0,
      },
      extraCalculate: {
        rawMilkNeedWeight: 0,
        rawMilkQuantity: 0,
        creamOutputWeight: 0,
        creamCurrentWeight: 0,
        freeFatMilkOutputWeight: 0,
        freeFatMilkCurrentWeight: 0,
        cottageCheeseWheyWeight: 0,
        cheeseWheyWeight: 0,
        butterMilkWeightFromHiFatProducts: 0,
      },
    }
  },
  //============================================================
  [calculatorActions.setChangeConfirmModal.call.type]: (
    state,
    action: TSetChangeConfirmModalReqAction,
  ) => {
    return {
      ...state,
      changeConfirmModal: action?.payload,
    }
  },
  [calculatorActions.setCalculateErrorAction.call.type]: (
    state,
    action: { type: typeof calculatorActions.setCalculateErrorAction.call.type; payload: string[] },
  ) => {
    return {
      ...state,
      calculateErrors: action?.payload,
    }
  },
})
