import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Header } from "antd/es/layout/layout"

import { adminModuleRoutes, clientModuleRoutes, modulesRoutes } from "Base/constants/routes"
import * as authPersistActions from "Base/store/authPersist/actions"
import { Button, Menu, Space } from "antd"

import { RootState } from "../../../Starter/store/configureStore"

export const MainHeader = () => {
  const dispatch = useDispatch()
  const {
    base: {
      authPersist: { auth },
    },
  } = useSelector((state: RootState) => state)
  const isClientButtonActive = useMemo(
    () => window.location.pathname.indexOf(`${modulesRoutes.client.root}`) !== -1,
    [window.location.pathname],
  )
  const isAdminButtonActive = useMemo(
    () => window.location.pathname.indexOf(`${modulesRoutes.admin.root}`) !== -1,
    [window.location.pathname],
  )

  return (
    <Header
      className='header'
      style={{
        display: "flex",
        paddingLeft: 0,
      }}
    >
      <div className='logo' />
      <Menu theme='dark' mode='horizontal'>
        <Link to={clientModuleRoutes.assortment.root}>
          <Button
            style={{
              height: 64,
              borderRadius: 0,
              border: 0,
              color: isClientButtonActive ? "white" : "gray",
            }}
            type={isClientButtonActive ? "primary" : undefined}
            ghost={!isClientButtonActive}
          >
            Калькулятор
          </Button>
        </Link>
        <Link to={adminModuleRoutes["finished-products"].root}>
          <Button
            style={{
              height: 64,
              borderRadius: 0,
              border: 0,
              color: isAdminButtonActive ? "white" : "gray",
            }}
            type={isAdminButtonActive ? "primary" : undefined}
            ghost={!isAdminButtonActive}
          >
            Админ-панель
          </Button>
        </Link>
      </Menu>
      {auth && (
        <Space style={{ justifyContent: "flex-end", width: "100%" }}>
          <Button onClick={() => dispatch(authPersistActions.logoutAction.call())}>Выход</Button>
        </Space>
      )}
    </Header>
  )
}
