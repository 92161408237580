import { compose, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import rootSaga from "Starter/store/rootSaga"
import { persistStore } from "redux-persist"
import createSagaMiddleware from "redux-saga"

import { rootReducer } from "./rootReducer"

const composeEnhancers =
  (process.env.REACT_APP_REDUX_DEVTOOLS === "enable"
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose

const sagaMiddleware = createSagaMiddleware()

// const middleware = [sagaMiddleware]; // .concat(logger),

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
  devTools: !!composeEnhancers,
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

sagaMiddleware.run(rootSaga)
