import * as calculatorActions from "Base/store/calculatorPersist/actions"
import { RootState } from "Starter/store/configureStore"
import { takeLatest, select, put } from "redux-saga/effects"

import { calculate } from "./services/calculation/calculate"
import { streamToVolumeConfigs, volumeToStreamConfigs } from "./services/utils/configs"

function* calculateSaga() {
  console.info("###########################")
  console.info("начало расчета поток - объем")
  console.info("###########################")
  try {
    const { inputData, assortments } = yield select(({ base }: RootState) => base.calculatorPersist)

    const { errors, ...result } = calculate(inputData, assortments, streamToVolumeConfigs)

    if (errors.length > 0) {
      yield put(calculatorActions.setCalculateErrorAction.call([...new Set(errors)]))
    } else {
      yield put(calculatorActions.calculateAction.success(result))
    }

    console.info("###########################")
    console.info("конец расчета поток - объем")
    console.info("###########################")
  } catch (e: unknown) {
    console.error(e)
    calculatorActions.calculateAction.failure()
  }
}

function* extraCalculateSaga() {
  console.info("###########################")
  console.info("начало расчета объем - поток")
  console.info("###########################")
  try {
    const { inputData, assortments } = yield select(({ base }: RootState) => base.calculatorPersist)

    const { errors, ...result } = calculate(inputData, assortments, volumeToStreamConfigs)

    if (errors.length === 0) {
      yield put(calculatorActions.extraCalculateAction.success(result))
    }

    console.info("###########################")
    console.info("конец расчета объем - поток")
    console.info("###########################")
  } catch (e: unknown) {
    calculatorActions.extraCalculateAction.failure()
  }
}

export function* calculatorWatcher() {
  yield takeLatest(calculatorActions.calculateAction.call.type, calculateSaga)
  yield takeLatest(calculatorActions.extraCalculateAction.call.type, extraCalculateSaga)
}
