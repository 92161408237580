import { TAssortment, TCalculate, TInputData } from "Base/store/calculatorPersist/types"

const roundNumber = (value?: number | null) => {
  if (!value) return 0
  return Math.round(value)
}

export const makeDataForExportCSV = ({
  calcData,
  inputData,
  assortments,
}: {
  calcData?: TCalculate
  inputData?: TInputData
  assortments: TAssortment[]
}) => {
  return [
    ["СЫРЬЕВОЙ БАЛАНС", ""],
    [],
    ["I. Исходные данные:"],
    ["Молоко сырое, количество, кг:", inputData?.rawMilkQuantity?.toFixed(2) || 0],
    ["Молоко сырое, МДЖ, %:", inputData?.rawMilkFat?.toFixed(2) || 0],
    ["МДЖ сепарированных сливок, %:", inputData?.creamFat?.toFixed(2) || 0],
    ["МДЖ обрата, %:", inputData?.freeFatMilkFat?.toFixed(2) || 0],
    ["МДЖ пахты, %:", inputData?.butterMilkFat?.toFixed(2) || 0],
    [],
    ["II. Ассортимент"],
    ...assortments.map((obj) => {
      return [
        `${obj.product.name} (${obj.product.fatPercentage}%):`,
        obj.currentWeight,
        obj.normalizationMethod === "stream" ? "нормализация в потоке" : "объёмная нормализация",
      ]
    }),
    [],
    ["III. Баланс", "", "", "IV. Вторичное сырье:"],
    [
      "Расход сырого молока, кг:",
      roundNumber(calcData?.rawMilkNeedWeight),
      "",
      "Творожная сыворотка, кг:",
      roundNumber(calcData?.cottageCheeseWheyWeight),
    ],
    [
      "Остаток сырого молока, кг:",
      roundNumber(calcData?.rawMilkQuantity),
      "",
      "Подсырная сыворотка, кг:",
      roundNumber(calcData?.cheeseWheyWeight),
    ],
    [
      "Выход сливок, кг:",
      roundNumber(calcData?.creamOutputWeight),
      "",
      "Пахта, кг:",
      roundNumber(calcData?.butterMilkWeightFromHiFatProducts),
    ],
    ["Остаток сливок, кг:", roundNumber(calcData?.creamCurrentWeight)],
    ["Выход обрата, кг:", roundNumber(calcData?.freeFatMilkOutputWeight)],
    ["Остаток обрата, кг:", roundNumber(calcData?.freeFatMilkCurrentWeight)],
  ]
}
