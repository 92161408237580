import { baseCSF } from "Base/utils/store"

import { TSetPasswordReqPayload } from "./types"

const prefix = "auth-persist-"

export const setPasswordAction = baseCSF<TSetPasswordReqPayload, undefined, undefined>(
  prefix,
  "set-password",
)

export const logoutAction = baseCSF<undefined, undefined, undefined>(prefix, "logout")
