import { apiRequest } from "Base/provider/sagas"
import * as mixesActions from "Base/store/mixes/actions"
import {
  DeleteMixesResp,
  GetMixesResp,
  PostMixesResp,
  PutMixesResp,
} from "Base/types/provider/api/mixes"
import { takeLatest, call, put } from "redux-saga/effects"

import { TDeleteMixesReqAction, TPostMixesReqAction, TPutMixesReqAction } from "./types"

export function* getMixesSaga() {
  try {
    const resp: GetMixesResp = yield call(apiRequest, "getMixes")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(mixesActions.getMixesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      mixesActions.getMixesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postMixesSaga({ payload }: TPostMixesReqAction) {
  try {
    const resp: PostMixesResp = yield call(apiRequest, "postMixes", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(mixesActions.postMixesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      mixesActions.postMixesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putMixesSaga({ payload }: TPutMixesReqAction) {
  try {
    const resp: PutMixesResp = yield call(apiRequest, "putMixes", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(mixesActions.putMixesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      mixesActions.putMixesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteMixesSaga({ payload }: TDeleteMixesReqAction) {
  try {
    const resp: DeleteMixesResp = yield call(apiRequest, "deleteMixes", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(mixesActions.deleteMixesAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      mixesActions.deleteMixesAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* mixesWatcher() {
  yield takeLatest(mixesActions.getMixesAction.call.type, getMixesSaga)
  yield takeLatest(mixesActions.postMixesAction.call.type, postMixesSaga)
  yield takeLatest(mixesActions.putMixesAction.call.type, putMixesSaga)
  yield takeLatest(mixesActions.deleteMixesAction.call.type, deleteMixesSaga)
}
