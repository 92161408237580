import { TErrorResponse } from "Base/types/provider/response"
import { notification } from "antd"
import axios from "axios"

const requireCodes = [200, 201, 400, 401, 403, 404, 405, 409, 422, 429, 500, 502, 504]

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  validateStatus: (status) => requireCodes.includes(status),
  withCredentials: true,
})

export default class ApiProviderDefault {
  static request<T, R>(
    url: string,
    method: "POST" | "GET" | "PUT" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH",
    params: any,
    data?: T,
    headers?: Headers,
  ): Promise<R | TErrorResponse> {
    // @ts-ignore
    return axiosInstance({
      url,
      method,
      params,
      data,
      headers,
    })
      .then((response) => {
        switch (response.status) {
          case 400:
          case 401:
          case 403:
          case 404:
          case 405:
          case 409:
          case 422:
          case 429:
          case 500:
          case 502:
            notification.error({
              message: response.data.error.message,
              description: JSON.stringify(response.data.error.data.errors),
              placement: "topRight",
              type: "error",
            })
            return {
              status: response.status,
              detail: response.data.error,
              success: false,
            }
          case 504:
            return {
              status: response.status,
              detail: "TIME_OUT",
              success: false,
            }
          default:
            return {
              data: response.data,
              status: response.status,
              success: true,
            }
        }
      })
      .catch((error) => ({
        success: false,
        error: error.message,
      }))
  }
}
