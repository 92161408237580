import React from "react"
import { Route, Navigate } from "react-router-dom"

import { Mixes } from "AdminModule/pages/Mixes"
import { Products } from "AdminModule/pages/Products"
import { ProductsTypes } from "AdminModule/pages/ProductsTypes"
import { adminModuleRoutes } from "Base/constants/routes/adminModuleRoutes"
import { AuthLayout } from "Base/layouts/AuthLayout"
import { MainLayout } from "Base/layouts/MainLayout"

export const getAdminRouters = () => (
  <>
    <Route
      path={adminModuleRoutes["finished-products"].root}
      element={
        <MainLayout moduleType='admin'>
          <AuthLayout>
            <Products />
          </AuthLayout>
        </MainLayout>
      }
    />
    <Route
      path={adminModuleRoutes["product-types"].root}
      element={
        <MainLayout moduleType='admin'>
          <AuthLayout>
            <ProductsTypes />
          </AuthLayout>
        </MainLayout>
      }
    />
    <Route
      path={adminModuleRoutes.mixes.root}
      element={
        <MainLayout moduleType='admin'>
          <AuthLayout>
            <Mixes />
          </AuthLayout>
        </MainLayout>
      }
    />
    <Route
      path={`${adminModuleRoutes.root}/*`}
      element={<Navigate to={adminModuleRoutes["finished-products"].root} replace={false} />}
    />
  </>
)
