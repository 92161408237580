export function getProductType({
  _currentFat,
  creamFat,
  freeFatMilkFat,
  lowFatMilkVolume,
  rawMilkFat,
}: {
  _currentFat: number
  freeFatMilkFat: number
  rawMilkFat: number
  creamFat: number
  lowFatMilkVolume: boolean
}) {
  if (_currentFat <= rawMilkFat && lowFatMilkVolume && _currentFat <= freeFatMilkFat) {
    return "freeFatProduct"
  } else if (_currentFat <= rawMilkFat && !(lowFatMilkVolume && _currentFat <= freeFatMilkFat)) {
    return "lowFatProduct"
  } else if (_currentFat > rawMilkFat && _currentFat <= creamFat) {
    return "mediumFatProduct"
  } else if (_currentFat > rawMilkFat && _currentFat > creamFat) {
    return "hiFatProduct"
  } else {
    throw new Error("ошибка расчета: не удалось определить жирность продукта")
  }
}
