import ApiProvider from "Base/provider/ApiProvider"
import { RootState } from "Starter/store/configureStore"
import { call, select } from "redux-saga/effects"

export function* handlerResponse({
  response,
  parameters,
  Api,
  method,
  numberOfReq = 0,
}: {
  response: any
  parameters: any
  Api: any
  method: any
  numberOfReq?: number
}): any {
  try {
    if (response.status === 504 && numberOfReq < 3) {
      response = yield call([Api, method], parameters)
      return yield call(handlerResponse, {
        response,
        parameters,
        Api,
        method,
        numberOfReq: numberOfReq + 1,
      })
    }

    return response
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getApiRequestSaga = <T extends RootState>(Api: any) =>
  function* apiRequestSagaDefault(apiMethod: string, options?: any): any {
    const { auth } = yield select(({ base }: RootState) => base.authPersist)

    const parameters = { ...options }
    const method = Api[apiMethod]
    parameters.headers = {
      ...parameters.headers,
      // "X-Api-Key": "pVmOYs",
      "X-Api-Key": auth?.password,
    }

    try {
      const response = yield call([Api, method], parameters)
      return yield call(handlerResponse, {
        response,
        parameters,
        Api,
        method,
      })
    } catch (e) {
      console.log(e)
      return null
    } finally {
      // always runs
    }
  }

export const apiRequest = getApiRequestSaga<RootState>(ApiProvider)
