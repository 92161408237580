import { TDairyProcessingMetrics, TProductFatType, TStep2Metrics } from "../../types"
import {
  calculateForFreeFatProductsInStream,
  calculateForFreeFatProductsInVolume,
  calculateForHiFatProductsInStream,
  calculateForLowFatProductsInStream,
  calculateForLowFatProductsInVolume,
  calculateForMediumFatProductsInStream,
  calculateForMediumFatProductsInVolume,
} from "../calculation"

export const getProductTypeConfig = (
  productFatType: TProductFatType,
  {
    state,
    step2Metrics,
    _currentWeight,
    creamFat,
    _currentFat,
    rawMilkFat,
    freeFatMilkFat,
    butterMilkFat,
  }: {
    state: TDairyProcessingMetrics
    step2Metrics: TStep2Metrics
    _currentWeight: number
    creamFat: number
    _currentFat: number
    rawMilkFat: number
    freeFatMilkFat: number
    butterMilkFat: number
    isButter: boolean
  },
) => {
  const productConfigByType = {
    freeFatProduct: {
      shouldCalculateCheeseAndCottage: true,
      calculateInStream: () => calculateForFreeFatProductsInStream(state, _currentWeight),
      calculateInVolume: () => calculateForFreeFatProductsInVolume(state, _currentWeight),
    },
    lowFatProduct: {
      shouldCalculateCheeseAndCottage: true,
      calculateInStream: () =>
        calculateForLowFatProductsInStream({
          state,
          _currentWeight,
          creamFat,
          _currentFat,
          rawMilkFat,
        }),
      calculateInVolume: () =>
        calculateForLowFatProductsInVolume({
          state,
          _currentFat,
          _currentWeight,
          freeFatMilkFat,
          rawMilkFat,
        }),
    },
    mediumFatProduct: {
      shouldCalculateCheeseAndCottage: true,
      calculateInStream: () =>
        calculateForMediumFatProductsInStream({
          state,
          _currentFat,
          _currentWeight,
          creamFat,
          freeFatMilkFat,
        }),
      calculateInVolume: () =>
        calculateForMediumFatProductsInVolume({
          state,
          step2Metrics,
          _currentFat,
          _currentWeight,
          creamFat,
          freeFatMilkFat,
          rawMilkFat,
        }),
    },
    hiFatProduct: {
      shouldCalculateCheeseAndCottage: false,
      calculateInStream: () =>
        calculateForHiFatProductsInStream({
          state,
          _currentFat,
          _currentWeight,
          butterMilkFat,
          creamFat,
        }),
      calculateInVolume: () =>
        calculateForHiFatProductsInStream({
          state,
          _currentFat,
          _currentWeight,
          butterMilkFat,
          creamFat,
        }),
    },
  }

  return productConfigByType[productFatType]
}
