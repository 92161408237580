import { createReducer } from "@reduxjs/toolkit"
import { RequestInfoChildState } from "Base/constants/statesDefault/reducer"
import * as productsActions from "Base/store/products/actions"
import { TGetProductsData } from "Base/types/provider/api/products"
import { RequestInfoChildType } from "Base/types/store/reducer"

import {
  TPutProductsData,
  TDeleteProductsData,
  TPostProductsData,
} from "../../types/provider/api/products"
import {
  TDeleteProductsFailureAction,
  TDeleteProductsSuccessAction,
  TPostProductsFailureAction,
  TPostProductsSuccessAction,
  TPutProductsFailureAction,
  TPutProductsSuccessAction,
  TGetProductsFailureAction,
  TGetProductsSuccessAction,
} from "./types"

export type ProductsStateType = {
  getProducts: {
    data?: TGetProductsData
  } & RequestInfoChildType
  postProducts: {
    data?: TPostProductsData
  } & RequestInfoChildType
  putProducts: {
    data?: TPutProductsData
  } & RequestInfoChildType
  deleteProducts: {
    data?: TDeleteProductsData
  } & RequestInfoChildType
}

const initialState: ProductsStateType = {
  getProducts: {
    ...RequestInfoChildState,
  },
  postProducts: {
    ...RequestInfoChildState,
  },
  putProducts: {
    ...RequestInfoChildState,
  },
  deleteProducts: {
    ...RequestInfoChildState,
  },
}

export const productsReducer = createReducer(initialState, {
  [productsActions.getProductsAction.call.type]: (state) => ({
    ...state,
    getProducts: {
      ...state.getProducts,
      isLoading: true,
    },
  }),
  [productsActions.getProductsAction.success.type]: (state, action: TGetProductsSuccessAction) => ({
    ...state,
    getProducts: {
      ...state.getProducts,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsActions.getProductsAction.failure.type]: (state, action: TGetProductsFailureAction) => ({
    ...state,
    getProducts: {
      ...state.getProducts,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [productsActions.postProductsAction.call.type]: (state) => ({
    ...state,
    postProducts: {
      ...state.postProducts,
      isLoading: true,
    },
  }),
  [productsActions.postProductsAction.success.type]: (
    state,
    action: TPostProductsSuccessAction,
  ) => ({
    ...state,
    postProducts: {
      ...state.postProducts,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsActions.postProductsAction.failure.type]: (
    state,
    action: TPostProductsFailureAction,
  ) => ({
    ...state,
    postProducts: {
      ...state.postProducts,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [productsActions.putProductsAction.call.type]: (state) => ({
    ...state,
    putProducts: {
      ...state.putProducts,
      isLoading: true,
    },
  }),
  [productsActions.putProductsAction.success.type]: (state, action: TPutProductsSuccessAction) => ({
    ...state,
    putProducts: {
      ...state.putProducts,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsActions.putProductsAction.failure.type]: (state, action: TPutProductsFailureAction) => ({
    ...state,
    putProducts: {
      ...state.putProducts,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
  //============================================================
  [productsActions.deleteProductsAction.call.type]: (state) => ({
    ...state,
    deleteProducts: {
      ...state.deleteProducts,
      isLoading: true,
    },
  }),
  [productsActions.deleteProductsAction.success.type]: (
    state,
    action: TDeleteProductsSuccessAction,
  ) => ({
    ...state,
    deleteProducts: {
      ...state.deleteProducts,
      data: action.payload,
      isLoading: false,
    },
  }),
  [productsActions.deleteProductsAction.failure.type]: (
    state,
    action: TDeleteProductsFailureAction,
  ) => ({
    ...state,
    deleteProducts: {
      ...state.deleteProducts,
      error: action.payload.error,
      isLoading: false,
    },
  }),
  //============================================================
})
