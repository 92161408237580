import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Search from "antd/es/input/Search"
import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import * as productsTypesActions from "Base/store/productsTypes/actions"
import { TProductType } from "Base/types/provider/api/productsTypes"
import { RootState } from "Starter/store/configureStore"
import { Button, Checkbox, Form, Input, Modal, Space, Table, Typography } from "antd"

const { Title } = Typography
const { Item } = Form

type TFormValues = TProductType

type TDataType = TProductType

export const ProductsTypes = () => {
  const dispatch = useDispatch()
  const {
    base: {
      productsTypes: { getProductsTypes, deleteProductsTypes, postProductsTypes, putProductsTypes },
    },
  } = useSelector((state: RootState) => state)
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [form] = Form.useForm()
  const id = form.getFieldValue("id")
  const [sortedInfo, setSortedInfo] = useState<SorterResult<TDataType>>({})

  const columns: ColumnsType<TDataType> = useMemo(() => {
    return [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        width: 60,
        sorter: (a, b) => a.id - b.id,
        sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      },
      {
        title: "Название",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "Имеется расход на 1 т",
        dataIndex: "mixConsumptionEnabled",
        key: "mixConsumptionEnabled",
        render: (_, record: TDataType) => {
          return <>{record.mixConsumptionEnabled ? "Да" : "Нет"}</>
        },
        // @ts-ignore
        sorter: (a, b) => a.mixConsumptionEnabled - b.mixConsumptionEnabled,
        sortOrder: sortedInfo.columnKey === "mixConsumptionEnabled" ? sortedInfo.order : null,
      },
      {
        title: "Расчет обрата для низкожирного творога",
        dataIndex: "lowFatMilkVolume",
        key: "lowFatMilkVolume",
        render: (_, record: TDataType) => {
          return <>{record.lowFatMilkVolume ? "Да" : "Нет"}</>
        },

        // @ts-ignore
        sorter: (a, b) => a.lowFatMilkVolume - b.lowFatMilkVolume,
        sortOrder: sortedInfo.columnKey === "lowFatMilkVolume" ? sortedInfo.order : null,
      },
      {
        title: "Расчет творожной сыворотки",
        dataIndex: "cottageCheeseWheyEnabled",
        key: "cottageCheeseWheyEnabled",
        render: (_, record: TDataType) => {
          return <>{record.cottageCheeseWheyEnabled ? "Да" : "Нет"}</>
        },

        // @ts-ignore
        sorter: (a, b) => a.cottageCheeseWheyEnabled - b.cottageCheeseWheyEnabled,
        sortOrder: sortedInfo.columnKey === "cottageCheeseWheyEnabled" ? sortedInfo.order : null,
      },
      {
        title: "Расчет подсырной сыворотки",
        dataIndex: "cheeseWheyEnabled",
        key: "cheeseWheyEnabled",
        render: (_, record: TDataType) => {
          return <>{record.cheeseWheyEnabled ? "Да" : "Нет"}</>
        },

        // @ts-ignore
        sorter: (a, b) => a.cheeseWheyEnabled - b.cheeseWheyEnabled,
        sortOrder: sortedInfo.columnKey === "cheeseWheyEnabled" ? sortedInfo.order : null,
      },
      {
        title: "Расчет пахты",
        dataIndex: "buttermilkEnabled",
        key: "buttermilkEnabled",
        render: (_, record: TDataType) => {
          return <>{record.buttermilkEnabled ? "Да" : "Нет"}</>
        },

        // @ts-ignore
        sorter: (a, b) => a.buttermilkEnabled - b.buttermilkEnabled,
        sortOrder: sortedInfo.columnKey === "buttermilkEnabled" ? sortedInfo.order : null,
      },
      {
        title: "Действие",
        key: "action",
        width: 150,
        render: (_, record: TDataType) => {
          return (
            <Space size='middle'>
              <Button
                onClick={() => {
                  form.setFieldValue("id", record.id)
                  form.setFieldValue("name", record.name)
                  form.setFieldValue("mixConsumptionEnabled", record.mixConsumptionEnabled)
                  form.setFieldValue("lowFatMilkVolume", record.lowFatMilkVolume)
                  form.setFieldValue("cottageCheeseWheyEnabled", record.cottageCheeseWheyEnabled)
                  form.setFieldValue("cheeseWheyEnabled", record.cheeseWheyEnabled)
                  form.setFieldValue("buttermilkEnabled", record.buttermilkEnabled)
                  setIsOpen(true)
                }}
                type='primary'
                icon={<EditOutlined />}
              >
                Изменить
              </Button>
            </Space>
          )
        },
      },
    ]
  }, [getProductsTypes.data, sortedInfo])

  useEffect(() => {
    if (postProductsTypes.isLoading || putProductsTypes.isLoading || deleteProductsTypes.isLoading)
      return
    dispatch(productsTypesActions.getProductsTypesAction.call({}))
  }, [postProductsTypes.isLoading, putProductsTypes.isLoading, deleteProductsTypes.isLoading])

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
  }, [isOpen])

  const onFinish = (values: TFormValues) => {
    values.name = values.name.trim()
    id
      ? dispatch(
          productsTypesActions.putProductsTypesAction.call({
            ...values,
            id,
          }),
        )
      : dispatch(productsTypesActions.postProductsTypesAction.call(values))
    setIsOpen(false)
  }

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const deleteProductType = () => {
    dispatch(productsTypesActions.deleteProductsTypesAction.call({ id }))
    setIsOpen(false)
    setIsOpenDeleteConfirmModal(false)
  }
  const productsTypesData = useMemo(() => {
    if (!getProductsTypes.data?.result) return []
    return searchValue
      ? getProductsTypes.data?.result.filter(
          (productsTypes) =>
            productsTypes.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1,
        )
      : getProductsTypes.data?.result
  }, [getProductsTypes.data, searchValue])

  const handleChange: TableProps<TDataType>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<TDataType>)
  }
  return (
    <>
      <Space direction='vertical'>
        <Space>
          <Title level={2} style={{ marginBottom: 0 }}>
            Типы продуктов
          </Title>
          <Button onClick={() => setIsOpen(true)} type='primary' icon={<PlusCircleOutlined />}>
            Добавить
          </Button>
        </Space>
        <Space style={{ marginTop: 30 }}>
          <Search onChange={onChange} placeholder='Поиск по названию' />
        </Space>
        <Space style={{ marginTop: 30 }}>
          <Table
            style={{ maxWidth: 1800 }}
            columns={columns}
            dataSource={productsTypesData}
            onChange={handleChange}
            pagination={false}
            locale={{ emptyText: "Нет данных" }}
          />
        </Space>
      </Space>
      <Modal
        title='Добавление/редактирование типа продуктов'
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Form
          name='mixes'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 6 }}
          onFinish={onFinish}
        >
          <Item name='name' label='Название' required>
            <Input placeholder='Название' required />
          </Item>
          <Item name='mixConsumptionEnabled' valuePropName='checked' label='Расход на 1 т:'>
            <Checkbox>имеется расход на 1 т</Checkbox>
          </Item>
          <Item name='lowFatMilkVolume' valuePropName='checked' label=' ' colon={false}>
            <Checkbox>Расчет обрата для низкожирного творога</Checkbox>
          </Item>
          <Item name='cottageCheeseWheyEnabled' valuePropName='checked' label=' ' colon={false}>
            <Checkbox>Расчет творожной сыворотки</Checkbox>
          </Item>
          <Item name='cheeseWheyEnabled' valuePropName='checked' label=' ' colon={false}>
            <Checkbox>Расчет подсырной сыворотки</Checkbox>
          </Item>
          <Item name='buttermilkEnabled' valuePropName='checked' label=' ' colon={false}>
            <Checkbox>Расчет пахты</Checkbox>
          </Item>
          <Space direction={"horizontal"}>
            <Button type='primary' htmlType='submit'>
              Сохранить
            </Button>
            {id && (
              <Button
                onClick={() => setIsOpenDeleteConfirmModal(true)}
                type='primary'
                danger
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>
            )}
          </Space>
        </Form>
      </Modal>
      <Modal
        title='Подтверждение действия'
        centered
        open={isOpenDeleteConfirmModal}
        onOk={() => setIsOpenDeleteConfirmModal(false)}
        onCancel={() => setIsOpenDeleteConfirmModal(false)}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            Вы уверены, что хотите удалить данный Тип продукта? В этом случае будут удалены все
            продукты, относящиеся к данному типу продукта.
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={deleteProductType}
              type='primary'
              htmlType='submit'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
            <Button onClick={() => setIsOpenDeleteConfirmModal(false)} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </>
  )
}
