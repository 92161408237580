import { TDairyProcessingMetrics } from "../../types"

/**
 * @description Расчет требуемого количества обрата для обезжиренных продуктов (объем)
 */
export const calculateForFreeFatProductsInVolume = (
  state: TDairyProcessingMetrics,
  _currentWeight: number,
) => {
  return {
    ...state,
    freeFatMilkVolumeForFreeFatProducts: state.freeFatMilkVolumeForFreeFatProducts + _currentWeight,
  }
}
