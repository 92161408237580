import React from "react"
import { NavLink } from "react-router-dom"

import { Button, Layout, Menu, theme } from "antd"
import hash from "object-hash"

const { Content, Sider } = Layout

type MenuLayoutProps = {
  children?: React.ReactNode
  menuItems: {
    label: string
    key: React.Key
    icon?: React.ReactNode
    disable?: boolean
  }[]
}

export function MenuLayout({ children, menuItems }: MenuLayoutProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  return (
    <Layout>
      <Sider width={"245px"}>
        <Menu style={{ paddingTop: "20px" }} theme='dark'>
          {menuItems?.map((menuItem) => {
            return (
              <NavLink key={hash(menuItem)} to={menuItem?.key.toString()}>
                {(event) => {
                  return (
                    <Button
                      disabled={menuItem.disable}
                      icon={menuItem.icon}
                      style={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        borderRadius: 0,
                        border: 0,
                        color: event.isActive ? "white" : "gray",
                      }}
                      type={event.isActive ? "primary" : undefined}
                      ghost={!event.isActive}
                    >
                      {menuItem.label}
                    </Button>
                  )
                }}
              </NavLink>
            )
          })}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: "16px" }}>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
