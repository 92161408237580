import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { CalculatorOutlined, CaretLeftOutlined } from "@ant-design/icons"
import { clientModuleRoutes } from "Base/constants/routes"
import * as calculatorActions from "Base/store/calculatorPersist/actions"
import { ErrorCard } from "Base/ui/ErrorCard"
import { makeDataForExportCSV } from "Base/utils/makeDataForExportCSV"
import { RootState } from "Starter/store/configureStore"
import { Button, Form, InputNumber, Space, Typography } from "antd"

import { CalculationResults } from "../Assortments/components/CalculationResults"
import styles from "./CalculationOfRawMaterials.module.scss"

const { Title } = Typography
const { Item } = Form

export const CalculationOfRawMaterials = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    base: {
      calculatorPersist: { assortments, inputData, calculate, extraCalculate, calculateErrors },
    },
  } = useSelector((state: RootState) => state)
  const [form] = Form.useForm()

  useEffect(() => {
    return () => {
      dispatch(calculatorActions.setInitInputDataAction.call())
      dispatch(calculatorActions.setInitCalculateDataAction.call())
      dispatch(calculatorActions.setCalculateErrorAction.call([]))
    }
  }, [])

  const onChange = (event: { name: string; value: number | null }) => {
    dispatch(
      calculatorActions.setInputDataAction.call({
        [event.name]: event.value,
      }),
    )
    dispatch(calculatorActions.setInitCalculateDataAction.call())
    dispatch(calculatorActions.setCalculateErrorAction.call([]))
  }

  const onClickCalculate = () => {
    dispatch(calculatorActions.calculateAction.call({}))
    if (assortments.length > 1) {
      dispatch(calculatorActions.extraCalculateAction.call({}))
    }
  }

  const shouldDoSecondCalculation = useMemo(() => {
    const hasStreamNormalization = assortments.some((el) => el.normalizationMethod === "stream")
    const hasVolumeNormalization = assortments.some((el) => el.normalizationMethod === "volume")

    return assortments.length > 1 && hasStreamNormalization && hasVolumeNormalization
  }, [assortments])

  const isFreeFatMilkFatIsDisabled = useMemo(() => {
    if (inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat) {
      return false
      // const assortmentsFiltered = assortments.filter(
      //   (assortment) => assortment.product.fatPercentage > inputData.rawMilkFat!,
      // )
      // return assortmentsFiltered.length === 0
    }
    return true
  }, [inputData, assortments])

  const isButterMilkFatIsDisabled = useMemo(() => {
    if (inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat) {
      const assortmentsFiltered = assortments.filter(
        (assortment) => assortment.product.type.buttermilkEnabled,
      )
      return assortmentsFiltered.length === 0
    }
    return true
  }, [inputData, assortments])

  const isCalculationButtonIsDisabled = useMemo(() => {
    const hasRequiredInputData =
      inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat

    if (!hasRequiredInputData) {
      return true
    }

    if (!isFreeFatMilkFatIsDisabled && !inputData.freeFatMilkFat) {
      return true
    }

    if (!isButterMilkFatIsDisabled && !inputData.butterMilkFat) {
      return true
    }

    return false
  }, [isFreeFatMilkFatIsDisabled, isButterMilkFatIsDisabled, inputData])

  const isFillingInTheFields = useMemo(() => {
    return !!(
      inputData?.rawMilkQuantity ||
      inputData?.rawMilkFat ||
      inputData?.creamFat ||
      inputData?.freeFatMilkFat ||
      inputData?.butterMilkFat ||
      calculate?.rawMilkNeedWeight ||
      calculate?.rawMilkQuantity ||
      calculate?.creamOutputWeight ||
      calculate?.creamCurrentWeight ||
      calculate?.freeFatMilkOutputWeight ||
      calculate?.freeFatMilkCurrentWeight ||
      calculate?.cottageCheeseWheyWeight ||
      calculate?.cheeseWheyWeight ||
      calculate?.butterMilkWeightFromHiFatProducts
    )
  }, [inputData, calculate])

  return (
    <Space direction='vertical'>
      <Space>
        <Title level={2}>Расчет сырья</Title>
      </Space>
      <Space direction='vertical'>
        <Title level={3}>Вводные данные</Title>
        <Form form={form} className={styles.input_data_form}>
          <Form labelCol={{ span: 9 }} layout='horizontal'>
            <Title level={4}>Сырое молоко:</Title>
            <Space direction='vertical'>
              <Item label='Количество, кг' required>
                <InputNumber
                  style={{ width: 200 }}
                  name='rawMilkQuantity'
                  onChange={(value) => onChange({ name: "rawMilkQuantity", value })}
                  value={inputData.rawMilkQuantity}
                  placeholder='Количество, кг'
                  decimalSeparator=','
                  precision={2}
                  min={0}
                />
              </Item>
              <Item label='МДЖ, %' required>
                <InputNumber
                  style={{ width: 200 }}
                  name='rawMilkFat'
                  onChange={(value) => onChange({ name: "rawMilkFat", value })}
                  value={inputData.rawMilkFat}
                  placeholder='МДЖ, %'
                  decimalSeparator=','
                  precision={2}
                  max={10}
                  min={0}
                />
              </Item>
            </Space>
          </Form>
          <Form layout='horizontal' labelCol={{ span: 14 }}>
            <Title level={4}>Параметры:</Title>
            <Item label='МДЖ сепарированных сливок, %' required>
              <InputNumber
                style={{ width: 200 }}
                name='creamFat'
                onChange={(value) => onChange({ name: "creamFat", value })}
                value={inputData.creamFat}
                placeholder='МДЖ сепарированных сливок, %'
                decimalSeparator=','
                max={65}
                precision={2}
                min={0}
              />
            </Item>
            <Item label='МДЖ обрата, %' required={!isFreeFatMilkFatIsDisabled}>
              <InputNumber
                style={{ width: 200 }}
                name='freeFatMilkFat'
                onChange={(value) => onChange({ name: "freeFatMilkFat", value })}
                value={inputData.freeFatMilkFat}
                placeholder='МДЖ обрата, %'
                decimalSeparator=','
                precision={2}
                max={1.5}
                disabled={isFreeFatMilkFatIsDisabled}
              />
            </Item>
            <Item label='МДЖ пахты, %' required={!isButterMilkFatIsDisabled}>
              <InputNumber
                style={{ width: 200 }}
                name='butterMilkFat'
                onChange={(value) => onChange({ name: "butterMilkFat", value })}
                value={inputData.butterMilkFat}
                placeholder='МДЖ пахты, %'
                decimalSeparator=','
                precision={2}
                max={4}
                disabled={isButterMilkFatIsDisabled}
                min={0.01}
              />
            </Item>
          </Form>
        </Form>
        <Button
          onClick={onClickCalculate}
          style={{ marginTop: 30, marginBottom: 30 }}
          type='primary'
          icon={<CalculatorOutlined />}
          htmlType='submit'
          disabled={isCalculationButtonIsDisabled}
        >
          Произвести расчёт
        </Button>
      </Space>
      {calculateErrors.length > 0 && (
        <>
          <ErrorCard errors={calculateErrors} />
          <br />
        </>
      )}
      <CalculationResults
        dataForCSV={makeDataForExportCSV({ calcData: calculate, inputData, assortments })}
        title={`Результат расчета${shouldDoSecondCalculation ? " (поток → объем)" : ""} `}
        data={calculate}
      />
      {shouldDoSecondCalculation && (
        <>
          <br />
          <CalculationResults
            dataForCSV={makeDataForExportCSV({
              calcData: extraCalculate,
              inputData,
              assortments,
            })}
            title='Результат расчета (объем → поток)'
            data={extraCalculate}
          />
        </>
      )}

      <Space style={{ marginTop: 30 }}>
        <Button
          onClick={() => {
            isFillingInTheFields
              ? dispatch(
                  calculatorActions.setChangeConfirmModal.call({
                    desc: "Внимание! Введенные данные будут потеряны.",
                    confirm: () => {
                      navigate(clientModuleRoutes.assortment.root)
                    },
                  }),
                )
              : navigate(clientModuleRoutes.assortment.root)
          }}
          type='primary'
          icon={<CaretLeftOutlined />}
        >
          Изменить ассортимент
        </Button>
      </Space>
    </Space>
  )
}
