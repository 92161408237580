import { apiRequest } from "Base/provider/sagas"
import * as productsActions from "Base/store/products/actions"
import {
  TDeleteProductsResp,
  TGetProductsResp,
  TPutProductsResp,
  TPostProductsResp,
} from "Base/types/provider/api/products"
import { takeLatest, call, put } from "redux-saga/effects"

import { TDeleteProductsReqAction, TPostProductsReqAction, TPutProductsReqAction } from "./types"

export function* getProductsSaga() {
  try {
    const resp: TGetProductsResp = yield call(apiRequest, "getProducts")
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsActions.getProductsAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsActions.getProductsAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* postProductsSaga({ payload }: TPostProductsReqAction) {
  try {
    const resp: TPostProductsResp = yield call(apiRequest, "postProducts", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsActions.postProductsAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsActions.postProductsAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* putProductsSaga({ payload }: TPutProductsReqAction) {
  try {
    const resp: TPutProductsResp = yield call(apiRequest, "putProducts", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsActions.putProductsAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsActions.putProductsAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* deleteProductsSaga({ payload }: TDeleteProductsReqAction) {
  try {
    const resp: TDeleteProductsResp = yield call(apiRequest, "deleteProducts", {
      params: payload,
    })
    if (!resp.success) throw new Error(resp.detail || "Unknown error")
    yield put(productsActions.deleteProductsAction.success(resp.data))
  } catch (e: unknown) {
    yield put(
      productsActions.deleteProductsAction.failure({
        error: e?.toString() || "",
      }),
    )
    console.log(e)
  }
}

export function* productsWatcher() {
  yield takeLatest(productsActions.getProductsAction.call.type, getProductsSaga)
  yield takeLatest(productsActions.postProductsAction.call.type, postProductsSaga)
  yield takeLatest(productsActions.putProductsAction.call.type, putProductsSaga)
  yield takeLatest(productsActions.deleteProductsAction.call.type, deleteProductsSaga)
}
