import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { getAdminRouters } from "AdminModule/routes"
import { clientModuleRoutes } from "Base/constants/routes"
import { modulesRoutes } from "Base/constants/routes/modulesRoutes"
import { MainLayout } from "Base/layouts/MainLayout"
import { getClientRouters } from "ClientModule/routes"
import { Typography } from "antd"

const { Title } = Typography

export const BaseRouter = () => {
  return (
    <Routes>
      {getClientRouters()}
      {getAdminRouters()}
      <Route
        path={modulesRoutes.root}
        element={<Navigate to={clientModuleRoutes["assortment"].root} replace={false} />}
      />
      {/* <Route path='*' element={<Navigate to={modulesRoutes.root} />} /> */}
    </Routes>
  )
}
