export const getModulesRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Main",
    main: {
      root: `${url}/`,
      title: "MainModule",
    },
    admin: {
      root: `${url}/admin`,
      title: "Админ",
    },
    client: {
      root: `${url}/client`,
      title: "Клиент",
    },
  }
}

export const modulesRoutes = getModulesRoutes("")
