import { modulesRoutes } from "./modulesRoutes"

const getAdminRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Админ",
    "finished-products": {
      root: `${url}/finished-products`,
      title: "Готовые продукты",
    },
    "product-types": {
      root: `${url}/product-types`,
      title: "Типы продуктов",
    },
    mixes: {
      root: `${url}/mixes`,
      title: "Смеси",
    },
  }
}

export const adminModuleRoutes = getAdminRoutes(modulesRoutes.admin.root)
