import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { ForkOutlined, PartitionOutlined, BuildOutlined } from "@ant-design/icons"
import { adminModuleRoutes, clientModuleRoutes } from "Base/constants/routes"
import { MainHeader } from "Base/containers/MainHeader"
import { MenuLayout } from "Base/layouts/MenuLayout"
import * as calculatorActions from "Base/store/calculatorPersist/actions"
import { RootState } from "Starter/store/configureStore"
import { Button, Layout, Modal, Space, Typography } from "antd"

const { Title } = Typography

const menuAdminItems: {
  label: string
  key: React.Key
  icon?: React.ReactNode
}[] = [
  {
    label: adminModuleRoutes["finished-products"].title,
    key: adminModuleRoutes["finished-products"].root,
    icon: <BuildOutlined />,
  },
  {
    label: adminModuleRoutes.mixes.title,
    key: adminModuleRoutes.mixes.root,
    icon: <ForkOutlined />,
  },
  {
    label: adminModuleRoutes["product-types"].title,
    key: adminModuleRoutes["product-types"].root,
    icon: <PartitionOutlined />,
  },
]

type MainLayoutProps = {
  children?: React.ReactNode
  moduleType?: "admin" | "client"
}
export const MainLayout = ({ children, moduleType }: MainLayoutProps) => {
  const dispatch = useDispatch()
  const {
    base: {
      calculatorPersist: { assortments, changeConfirmModal },
    },
  } = useSelector((state: RootState) => state)

  const menuClientItems: {
    label: string
    key: React.Key
    icon?: React.ReactNode
    disable?: boolean
  }[] = useMemo(() => {
    return [
      {
        label: clientModuleRoutes["assortment"].title,
        key: clientModuleRoutes["assortment"].root,
        // icon: <ReadOutlined />,
      },
      {
        label: clientModuleRoutes["calculation-of-raw-materials"].title,
        key: clientModuleRoutes["calculation-of-raw-materials"].root,
        disable: !assortments.length,
        // icon: <CalculatorOutlined />,
      },
    ]
  }, [assortments])

  const menuItems = useMemo(() => {
    return moduleType ? (moduleType === "admin" ? menuAdminItems : menuClientItems) : []
  }, [moduleType, menuClientItems])

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MainHeader />
      <MenuLayout menuItems={menuItems}>{children}</MenuLayout>
      <Modal
        title='Подтверждение действия'
        centered
        open={!!changeConfirmModal}
        onOk={() => dispatch(calculatorActions.setChangeConfirmModal.call())}
        onCancel={() => dispatch(calculatorActions.setChangeConfirmModal.call())}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            {changeConfirmModal?.desc}
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                changeConfirmModal?.confirm()
                dispatch(calculatorActions.setChangeConfirmModal.call())
              }}
              type='primary'
              htmlType='submit'
            >
              Подтвердить
            </Button>
            <Button
              onClick={() => dispatch(calculatorActions.setChangeConfirmModal.call())}
              htmlType='submit'
            >
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </Layout>
  )
}
