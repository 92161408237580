import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { authPersistReducer } from "./authPersist/reducer"
import { calculatorPersistReducer } from "./calculatorPersist/reducer"
import { mixesReducer } from "./mixes/reducer"
import { productsReducer } from "./products/reducer"
import { productsTypesReducer } from "./productsTypes/reducer"

const authPersistConfig = {
  key: "alfa-auth-password",
  storage,
  whitelist: ["auth"],
  transforms: [
    // setTransformToken,
    // setTransformIsAuth,
  ],
}

const calculatorPersistConfig = {
  key: "calculator-persist",
  storage,
  whitelist: ["assortments"],
  transforms: [],
}
export const baseRootReducer = combineReducers({
  authPersist: persistReducer(authPersistConfig, authPersistReducer),
  products: productsReducer,
  mixes: mixesReducer,
  productsTypes: productsTypesReducer,
  calculatorPersist: persistReducer(calculatorPersistConfig, calculatorPersistReducer),
})
