import MixesProvider from "./api/mixes"
import ProductsProvider from "./api/products"
import ProductsTypesProvider from "./api/productsTypes"

const aggregation = (baseClass: any, ...mixins: any) => {
  const base = class _Combined extends baseClass {
    constructor(...args: any) {
      super(...args)
      mixins.forEach((mixin: any) => {
        mixin.prototype.initializer.call(this)
      })
    }
  }
  const copyProps = (target: any, source: any) => {
    Object.getOwnPropertyNames(source)
      // @ts-ignore
      .concat(Object.getOwnPropertySymbols(source))
      .forEach((prop) => {
        if (
          prop.match(
            /^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/,
          )
        )
          return
        Object.defineProperty(
          target,
          prop,
          // @ts-ignore
          Object.getOwnPropertyDescriptor(source, prop),
        )
      })
  }
  mixins.forEach((mixin: any) => {
    copyProps(base.prototype, mixin.prototype)
    copyProps(base, mixin)
  })
  return base
}

class ApiProvider extends aggregation(MixesProvider, ProductsProvider, ProductsTypesProvider) {}

export default ApiProvider
