import { baseCSF } from "Base/utils/store"

import {
  TCalculateReqPayload,
  TCalculateSuccessPayload,
  TSetAssortmentsReqPayload,
  TSetChangeConfirmModalReqPayload,
  TSetInputDataReqPayload,
} from "./types"

const prefix = "calculator-"

export const setAssortmentsAction = baseCSF<TSetAssortmentsReqPayload, undefined, undefined>(
  prefix,
  "set-assortments",
)
export const setInputDataAction = baseCSF<TSetInputDataReqPayload, undefined, undefined>(
  prefix,
  "set-input-data",
)
export const setInitInputDataAction = baseCSF<undefined, undefined, undefined>(
  prefix,
  "set-init-input-data",
)
export const calculateAction = baseCSF<TCalculateReqPayload, TCalculateSuccessPayload, undefined>(
  prefix,
  "calculate",
)
export const extraCalculateAction = baseCSF<
  TCalculateReqPayload,
  TCalculateSuccessPayload,
  undefined
>(prefix, "extraCalculate")
export const setInitCalculateDataAction = baseCSF<undefined, undefined, undefined>(
  prefix,
  "set-init-calculate-data",
)
export const setChangeConfirmModal = baseCSF<
  TSetChangeConfirmModalReqPayload,
  undefined,
  undefined
>(prefix, "set-change-confirm-modal")

export const setCalculateErrorAction = baseCSF<string[], string[], string[]>(
  prefix,
  "set-calculate-error",
)
