import { baseRootSaga } from "Base/store/rootSaga"
import { fork, all } from "redux-saga/effects"

export default function* rootSaga() {
  try {
    yield all([fork(baseRootSaga)])
  } finally {
    // always runs
  }
}
