import { TDairyProcessingMetrics, TStep2Metrics } from "../../types"

export function setupStep2Metrics(
  step2Metrics: TStep2Metrics,
  dairyProcessingMetrics: TDairyProcessingMetrics,
) {
  return {
    rawMilkNeedWeight:
      step2Metrics.rawMilkNeedWeight +
      dairyProcessingMetrics.rawMilkWeightForLowFatProducts +
      dairyProcessingMetrics.rawMilkWeightForMediumFatProducts +
      dairyProcessingMetrics.rawMilkWeightForHiFatProducts,

    creamWeightNeedWeight:
      dairyProcessingMetrics.creamWeightForLowFatProducts +
      dairyProcessingMetrics.creamWeightForMediumFatProducts +
      dairyProcessingMetrics.creamWeightForHiFatProducts,

    freeFatMilkNeedWeight:
      dairyProcessingMetrics.freeFatMilkVolumeForFreeFatProducts +
      dairyProcessingMetrics.freeFatMilkVolumeForLowFatProducts +
      dairyProcessingMetrics.freeFatMilkVolumeForMediumFatProducts +
      dairyProcessingMetrics.freeFatMilkVolumeForHiFatProducts,

    creamOutputWeight:
      step2Metrics.creamOutputWeight + dairyProcessingMetrics.creamWeightFromLowFatProducts,

    creamCurrentWeight:
      step2Metrics.creamCurrentWeight + dairyProcessingMetrics.creamWeightFromLowFatProducts,

    freeFatMilkOutputWeight:
      step2Metrics.freeFatMilkOutputWeight +
      dairyProcessingMetrics.freeFatMilkWeightFromHiFatProducts,

    freeFatMilkCurrentWeight:
      step2Metrics.freeFatMilkCurrentWeight +
      dairyProcessingMetrics.freeFatMilkWeightFromHiFatProducts,

    butterMilkWeight:
      step2Metrics.butterMilkWeight + dairyProcessingMetrics.butterMilkWeightFromHiFatProducts,
  }
}
