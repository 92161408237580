import { baseCSF } from "Base/utils/store"

import {
  TDeleteProductsFailurePayload,
  TDeleteProductsReqPayload,
  TDeleteProductsSuccessPayload,
  TPostProductsFailurePayload,
  TPostProductsReqPayload,
  TPostProductsSuccessPayload,
  TPutProductsFailurePayload,
  TPutProductsReqPayload,
  TPutProductsSuccessPayload,
  TGetProductsFailurePayload,
  TGetProductsReqPayload,
  TGetProductsSuccessPayload,
} from "./types"

const prefix = "products"

export const getProductsAction = baseCSF<
  TGetProductsReqPayload,
  TGetProductsSuccessPayload,
  TGetProductsFailurePayload
>(prefix, "get-products")

export const postProductsAction = baseCSF<
  TPostProductsReqPayload,
  TPostProductsSuccessPayload,
  TPostProductsFailurePayload
>(prefix, "post")

export const putProductsAction = baseCSF<
  TPutProductsReqPayload,
  TPutProductsSuccessPayload,
  TPutProductsFailurePayload
>(prefix, "put")

export const deleteProductsAction = baseCSF<
  TDeleteProductsReqPayload,
  TDeleteProductsSuccessPayload,
  TDeleteProductsFailurePayload
>(prefix, "delete")
